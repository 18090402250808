<template>
  <div class="py-4 w-full container flex flex-col justify-between">
    <el-tabs v-model="tabActive" @tab-click="handleChangeTab">
      <el-tab-pane label="Danh sách phòng" name="1">
        <room-service></room-service>
      </el-tab-pane>
      <el-tab-pane label="Danh sách lịch xếp giường" name="2">
        <bed-list></bed-list>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
import RoomService from '../RoomService/RoomService.vue'
import BedList from './components/BedList.vue'
import { mixinQueryParams } from '@/utils/mixinQueryParams'
export default {
  name: 'RoomManagement',
  mixins: [mixinQueryParams],
  components: { RoomService, BedList },
  data () {
    return {
      tabActive: '1'
    }
  },
  mounted () {
    if (this.$route.query.tab) this.tabActive = this.$route.query.tab
  },
  methods: {
    handleChangeTab () {
      // this.$router.push({ query: { tab: this.tabActive } })
      this.handleSetFilter({ tab: this.tabActive })
    }
  }
}
</script>
<style lang="scss" scoped>
</style>