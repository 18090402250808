<template>
  <div class="py-4 w-full container flex flex-col justify-between">
    <div class="relative flex items-center justify-center">
      <div class="flex items-center justify-center">
        <span
          class="txt-grey-900 text-3xl font-bold mt-1 leading-none text-pris uppercase"
          style="color: #20409B;"
        >{{ $t('Danh sách phòng') }}</span>
      </div>
    </div>
    <div class="flex mt-2">
      <div class="ml-auto">
        <button
          @click="handleCreateRoom(true)"
          type="button"
          class="flex items-center justify-center border-0 focus:outline-none bg-blue-700 hover:bg-blue-800 text-white transition-all duration-200 rounded-lg h-9 px-3 py-2"
        >
          <i class="el-icon-plus text-lg mr-2" />
          <span>{{ $t('Thêm phòng') }}</span>
        </button>
      </div>
    </div>
    <div class="py-3">
      <room-filters
        search-box-class="md:w-1/3 w-full"
        @setFilters="handleFilters"
        :default-filters="filterOptions"
      />
    </div>
    <div class="row" v-loading="loading" v-if="listRooms.length >0">
      <template>
        <div v-for="room in listRooms" :key="room.id" class="col-md-3 p-2">
          <div
            @click="handleDetailListBedInRoom(room)"
            class="radius-10 room-item cursor-pointer text-black h-full bg-white cs-box-shadow"
          >
            <div
              class="flex h-full p-2 flex-col gap-2"
              :class="room.status === ROOM_STATUS.CODE.ON_SERVICE&&'text-gray-400'"
            >
              <div class="flex flex-col gap-2">
                <div class="flex justify-between items-center">
                  <span class="font-bold fs-18">{{room.name }}</span>
                  <svg
                    class="cursor-pointer"
                    @click.stop="handleUpdateRoom(room, true)"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M20.5016 12H20.5106"
                      stroke="#292D32"
                      stroke-width="5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M11.998 12H12.007"
                      stroke="#292D32"
                      stroke-width="5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M3.49451 12H3.50349"
                      stroke="#292D32"
                      stroke-width="5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>
                <div class="capitalize fs-16">{{ room.location&&room.location.Name }}</div>
                <div class="fs-16 flex justify-between items-center">
                  <div class="flex-1">
                    <strong>{{ $t('Tổng số giường') }}:</strong>
                    <span>{{ ` ${room.total_bed}` }}</span>
                  </div>
                  <div
                    v-if="room.status === ROOM_STATUS.CODE.ON_SERVICE"
                    class="flex py-1 px-2 justify-center items-center rounded-3xl fs-12"
                    style="border:1px solid #F79009;color:#F79009"
                  >{{ $t('Bảo trì') }}</div>
                </div>
              </div>
              <div class="flex-1 flex flex-col justify-end">
                <div v-if="room.status !== ROOM_STATUS.CODE.AVAILABLE" class="vDivider m-0"></div>
                <div
                  class="flex justify-between item-center fs-16 w-full p-2 rounded-lg"
                  :style="room.status === ROOM_STATUS.CODE.AVAILABLE&&'background: rgb(205 254 199)'"
                >
                  <div class="flex gap-1 items-center">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M7 12.5C7.59334 12.5 8.17336 12.3241 8.66671 11.9944C9.16006 11.6648 9.54458 11.1962 9.77164 10.6481C9.9987 10.0999 10.0581 9.49667 9.94236 8.91473C9.8266 8.33279 9.54088 7.79824 9.12132 7.37868C8.70176 6.95912 8.16721 6.6734 7.58527 6.55764C7.00333 6.44189 6.40013 6.5013 5.85195 6.72836C5.30377 6.95542 4.83524 7.33994 4.50559 7.83329C4.17595 8.32664 4 8.90666 4 9.5C4 10.2956 4.31607 11.0587 4.87868 11.6213C5.44129 12.1839 6.20435 12.5 7 12.5ZM7 8.5C7.19778 8.5 7.39112 8.55865 7.55557 8.66853C7.72002 8.77841 7.84819 8.93459 7.92388 9.11732C7.99957 9.30004 8.01937 9.50111 7.98079 9.69509C7.9422 9.88907 7.84696 10.0673 7.70711 10.2071C7.56725 10.347 7.38907 10.4422 7.19509 10.4808C7.00111 10.5194 6.80004 10.4996 6.61732 10.4239C6.43459 10.3482 6.27841 10.22 6.16853 10.0556C6.05865 9.89112 6 9.69778 6 9.5C6 9.23478 6.10536 8.98043 6.29289 8.79289C6.48043 8.60536 6.73478 8.5 7 8.5ZM20 6.5H12C11.7348 6.5 11.4804 6.60536 11.2929 6.79289C11.1054 6.98043 11 7.23478 11 7.5V13.5H3V5.5C3 5.23478 2.89464 4.98043 2.70711 4.79289C2.51957 4.60536 2.26522 4.5 2 4.5C1.73478 4.5 1.48043 4.60536 1.29289 4.79289C1.10536 4.98043 1 5.23478 1 5.5V18.5C1 18.7652 1.10536 19.0196 1.29289 19.2071C1.48043 19.3946 1.73478 19.5 2 19.5C2.26522 19.5 2.51957 19.3946 2.70711 19.2071C2.89464 19.0196 3 18.7652 3 18.5V15.5H21V18.5C21 18.7652 21.1054 19.0196 21.2929 19.2071C21.4804 19.3946 21.7348 19.5 22 19.5C22.2652 19.5 22.5196 19.3946 22.7071 19.2071C22.8946 19.0196 23 18.7652 23 18.5V9.5C23 8.70435 22.6839 7.94129 22.1213 7.37868C21.5587 6.81607 20.7956 6.5 20 6.5ZM21 13.5H13V8.5H20C20.2652 8.5 20.5196 8.60536 20.7071 8.79289C20.8946 8.98043 21 9.23478 21 9.5V13.5Z"
                        :fill="room.status === ROOM_STATUS.CODE.ON_SERVICE?'#9CA3AF':'#20409B'"
                      />
                    </svg>
                    <span>{{ $t('Số giường trống') }}:</span>
                  </div>
                  <div
                    class="font-bold fs-20"
                    :style="room.status === ROOM_STATUS.CODE.ON_SERVICE? 'color:#F79009' : room.empty_bed===0?'color:#F04438':'color:#12B76A'"
                  >{{ room.empty_bed }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="flex mt-2 items-center">
          <AppPagination :paginationProps="pagination" @onChangePagination="handlePaging" />
        </div>
      </template>
    </div>
    <div class="flex justify-content-center mt-5 text-xl" v-else>Chưa có dữ liệu phòng điều trị</div>
    <ModalAddRoom @refresh="onRefresh" :update="isUpdate" :data-room="currentDataRoom" />
  </div>
</template>
<script>
import { AppPagination } from '@/components/Common'
import ModalAddHR from '../HealthRecords/ModalAdd.vue'
import Pagination from '../../components/Pagination'
import ModalAddPerson from '../../components/Person/ModalAddPerson'
import RoomFilters from '../../components/RoomServiceFilters'
import { ROOM_STATUS } from '../../utils/constants'
import ModalAddRoom from './Modal/ModalAddRoom.vue'

export default {
  components: {
    Pagination,
    ModalAddPerson,
    ModalAddHR,
    RoomFilters,
    AppPagination,
    ModalAddRoom
  },
  name: 'RoomService',
  computed: {},
  data () {
    return {
      isUpdate: false,
      loading: false,
      currentDataRoom: null,
      isUdate: false,
      ROOM_STATUS,
      pagination: {
        currentPage: 1,
        pageSize: 18,
        totalItems: 20
      },
      contacts: {},
      search: null,
      filterOptions: {
        keyword: '',
        fromDate: this.moment().format('YYYY-MM-DD'),
        toDate: this.moment()
          .add(7, 'days')
          .format('YYYY-MM-DD')
      },
      params: {
        keyword: '',
        page: 1,
        limit: 12
      },
      listRooms: []
    }
  },
  mounted () {
    this.getListRoom()
  },
  methods: {
    handleFilters (data) {
      this.filterOptions.keyword = data.search
      if (data.fromDate) {
        this.filterOptions.fromDate = data.fromDate
      }
      if (data.toDate) {
        this.filterOptions.toDate = data.toDate
      }
      this.onRefresh()
    },
    handlePaging (data) {
      if (data.currentPage) {
        this.pagination.currentPage = data.currentPage
      }
      if (data.pageSize) {
        this.pagination.pageSize = data.pageSize
      }
      this.onRefresh()
    },
    handleDetailListBedInRoom (room) {
      // const subBeds = room.total_bed - room.empty_bed
      if (room.status === ROOM_STATUS.CODE.ON_SERVICE) {
        this.$message({
          message: this.$t('Phòng đang trong trạng thái bảo trì'),
          type: 'error'
        })
        return
      }
      // else if (subBeds === room.total_bed && room.total_bed > 0) {
      //   this.$message({
      //     message: this.$t(
      //       'Phòng đã hết giường trong khoảng thời gian này'
      //     ),
      //     type: 'error'
      //   })
      //   return
      // }

      this.$router.push({
        path: `/doctor/room-service/list-bed/${room.ID}`,
        query: {
          fromDate: this.filterOptions.fromDate,
          toDate: this.filterOptions.toDate
        }
      })
    },
    onShowModalAdd (show) {
      window.$('#modal__add_room').modal(show ? 'show' : 'hide')
    },
    handleCreateRoom (show) {
      this.currentDataRoom = null
      this.isUpdate = false
      window.$('#modal__add_room').modal(show ? 'show' : 'hide')
    },
    handleUpdateRoom (room, show) {
      this.currentDataRoom = room
      this.isUpdate = true
      window.$('#modal__add_room').modal(show ? 'show' : 'hide')
    },
    getRoomStatus (room) {
      const subBeds = room.total_bed - room.empty_bed
      if (room.status === ROOM_STATUS.CODE.ON_SERVICE) {
        return {
          class: 'border border-primary rounded-full',
          text: 'Bảo trì'
        }
      } else if (subBeds === room.total_bed && room.total_bed > 0) {
        return {
          class: 'border border-primary rounded-full',
          text: 'Hết giường'
        }
      } else {
        return {
          class: '',
          text: ''
        }
      }
    },
    setBackGround (room) {
      const subBeds = room.total_bed - room.empty_bed
      if (room.status === ROOM_STATUS.CODE.ON_SERVICE) {
        return 'bg-yellow-200'
      } else if (subBeds === room.total_bed && room.total_bed > 0) {
        return 'bg-red-300'
      } else {
        return ''
      }

      // switch (roomStatus) {
      //   case ROOM_STATUS.CODE.AVAILABLE:
      //     return ''
      //   case ROOM_STATUS.CODE.OCCUPIED:
      //     return 'bg-red-300'
      //   case ROOM_STATUS.CODE.ON_SERVICE:
      //     return 'bg-yellow-200'
      //   default:
      //     return ''
      // }
    },
    getAdministrativeForms () {},
    formatDMY (row) {
      return row.birthday && window.moment(row.birthday).format('DD-MM-YYYY')
    },
    addHr (obj) {
      this.$refs.ModalAddHR.openDialog(obj, 'create')
    },
    onRefresh () {
      this.getListRoom()
    },
    async getListRoom () {
      const params = {
        page_num: this.pagination.currentPage,
        page_size: this.pagination.pageSize,
        from: this.filterOptions.fromDate,
        to: this.filterOptions.toDate,
        keyword: this.filterOptions.keyword,
        workspaceID: this.$globalClinic.id
      }
      this.loading = true
      await this.$rf
        .getRequest('DoctorRequest')
        .getListRoomService(params)
        .then(res => {
          const resp = res.data
          this.listRooms = resp.rooms
          this.pagination = {
            currentPage: resp.page.page_num,
            pageSize: resp.page.page_size,
            totalItems: resp.page.total
          }
          this.loading = false
        })
        .catch(err => {
          console.log(err)
          this.loading = false
        })
    }
  }
}
</script>
<style scoped>
.radius-10 {
  border-radius: 10px;
}

.room-item {
  display: flex;
  flex-direction: column;
  min-height: 10rem;
}
/* hr { */
/* height: 3px;
    width: 100%;
    border: 0;
    border-top: 2px solid #20419B;
    border-width: 10px;
    background-color: #20419B; */
/* border-top: 3px solid #20419B; */
/* } */
.vDivider {
  width: 100%;
  height: 1px;
  margin: 10px auto;
  background: #b5b5b5;
  border-bottom: 1px solid #b7b7b7;
}
.radius-20 {
  border-radius: 10px;
  border-color: #20419b;
}

.cs-box-shadow {
  box-shadow: 16px 10px 115px -34px rgba(195, 195, 195, 0.7);
}
</style>
