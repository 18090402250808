<template>
  <div>
    <el-dialog
      class="cs-dialog"
      fullscreen
      :visible.sync="dialogVisible"
      :before-close="handleClose"
      append-to-body
    >
      <template slot="title">
        <h3 class="text-black">Đăng ký lịch xếp giường</h3>
      </template>
      <div class="modal-body">
        <div>
          <h3>Thông tin người bệnh</h3>
          <el-select v-model="patient" placeholder="Chọn bệnh nhân" class="w-100" filterable>
            <el-option
              v-for="item in listPatients"
              :key="item.emr_id"
              :label="`${item.name} - ${item.medical_record_name}`"
              :value="item.emr_id"
            ></el-option>
          </el-select>
        </div>
        <div class="patient-info" v-if="!!patientInfo">
          <div class="patient-info-content">
            <p>Tên bệnh nhân: {{ patientInfo.name }}</p>
            <p>Mã điều trị: {{ patientInfo.medical_record_code }}</p>
            <p>Ngày sinh: {{ formatDate(patientInfo.birthday) }}</p>
            <p class="mb-0">Tên bệnh án: {{ patientInfo.medical_record_name }}</p>
          </div>
          <div class="patient-info-content" v-if="type === 'change'">
            <h3 class="font-semibold">Thông tin giường cũ</h3>
            <p>Tên giường: {{ bedInfo && bedInfo.bed && bedInfo.bed.name }}</p>
            <p>Loại giường: {{ bedInfo && bedInfo.bed && bedInfo.bed.type_bed }}</p>
            <p>Phòng: {{ bedInfo && bedInfo.room && bedInfo.room.name }}</p>
            <!-- <p>Ngày sinh: {{ formatDate(patientInfo.birthday) }}</p>
            <p class="mb-0">Tên bệnh án: {{ patientInfo.medical_record_name }}</p>-->
          </div>
          <div class="patient-info-content">
            <h3 class="font-semibold">Thông tin lịch xếp giường</h3>
            <div class="flex items-end gap-3">
              <div>
                <label for class="mb-1">Ngày giờ vào:</label>
                <div class="flex items-center gap-2">
                  <el-time-select
                    v-model="params.from_time"
                    :picker-options="{
                    start: '00:00',
                    step: '00:30',
                    end: '23:30'
                  }"
                    placeholder="Chọn giờ vào"
                  ></el-time-select>
                  <el-date-picker
                    v-model="params.from_date"
                    type="date"
                    placeholder="Chọn ngày vào"
                  ></el-date-picker>
                </div>
              </div>
              <div>
                <label for class="mb-1">Ngày giờ ra:</label>
                <div class="flex items-center gap-2">
                  <el-time-select
                    v-model="params.to_time"
                    :picker-options="{
                    start: '00:00',
                    step: '00:30',
                    end: '24:00'
                  }"
                    placeholder="Chọn giờ ra"
                  ></el-time-select>
                  <el-date-picker v-model="params.to_date" type="date" placeholder="Chọn ngày ra"></el-date-picker>
                </div>
              </div>
              <el-button
                type="primary"
                :disabled="!params.from_time || !params.from_date || !params.to_time || !params.to_date"
                :loading="loading_bed"
                @click="getListEmptyBed"
              >Tìm kiếm</el-button>
            </div>
          </div>
          <div class="patient-info-content">
            <h3 class="font-semibold">Giường</h3>
            <p>
              Giường còn trống:
              <span style="color: #20419b" class="font-medium">{{ totalBeds }}</span>
            </p>
            <div class="list-bed" v-loading="loading_bed">
              <div
                v-for="item in listBeds"
                :key="item.id"
                class="room-bed"
                :class="selectedBed === item.id ? 'selected' : ''"
                @click="handleSelectBed(item)"
              >
                <p class="font-medium bed">Giường: {{item.name}}</p>
                <p class="mb-0">Phòng: {{item.name_room}}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="flex items-center justify-end gap-2 mt-3">
          <el-button @click="handleClose">Đóng</el-button>
          <el-button type="primary" @click="handleSave" :loading="isLoading">Xác nhận</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
  
  <script>
// import appUtils from '../../utils/appUtils'
import { mixinGetPersonInfo } from '@/utils/mixinGetPersonInfo'
import vSelect from 'vue-select'

export default {
  name: 'ModalRegisterRoom',
  mixins: [mixinGetPersonInfo],
  components: { vSelect },
  data () {
    return {
      dialogVisible: false,
      isLoading: false,
      loading_bed: false,
      patient: '',
      listPatients: [],
      params: {
        from_time: '',
        to_time: '',
        from_date: '',
        to_date: '',
        bed_id: ''
      },
      listBeds: [],
      selectedBed: '',
      totalBeds: 0,
      type: '',
      bedInfo: {}
    }
  },
  computed: {
    patientInfo () {
      return this.listPatients?.find(elm => elm.emr_id === this.patient)
    },
    room_id () {
      return this.listBeds?.find(elm => elm.id === this.selectedBed)?.room_id
    }
  },
  watch: {
    'params.from_time' (value) {
      if (!value) {
        this.params.to_time = ''
        this.params.to_date = ''
        this.params.from_date = ''
      }
      this.params.to_time = this.add4Hours(value)
      let from_hour = value?.slice(0, 2)
      if (+from_hour < 20) this.params.to_date = this.params.from_date
    },
    'params.from_date' (value) {
      if (!value || value === null) {
        this.params.to_date = ''
        this.params.to_time = ''
      }
      let from_hour = this.params.from_time?.slice(0, 2)
      // let to_hour = this.params.to_time?.slice(0, 2)
      if (+from_hour >= 20) {
        this.params.to_date = new Date(
          new Date(value).getTime() + 24 * 3600 * 1000
        )
      } else this.params.to_date = this.params.from_date
    },
    patientInfo () {
      this.params.from_time = this.roundedTime(
        window.moment(new Date()).format('HH:mm')
      )
      this.params.to_time = this.roundedTime(
        window
          .moment(new Date())
          .add(4, 'hours')
          .format('HH:mm')
      )
      this.params.from_date = new Date().getTime()
      this.params.to_date = new Date(this.params.from_date + 4 * 3600 * 1000)
    }
  },
  created () {
    //
  },
  methods: {
    handleShowModal (isShow, bedInfo = {}) {
      this.dialogVisible = isShow
      if (!isShow) return
      if (bedInfo.ID) {
        this.type = 'change'
        this.patient = bedInfo.emr_id
        this.bedInfo = bedInfo
      }
      this.getListPatient()
    },
    handleClose () {
      this.params = {
        from_time: '',
        to_time: '',
        bed_id: ''
      }
      this.patient = ''
      this.selectedBed = ''
      this.listBeds = []
      this.totalBeds = 0
      this.type = ''
      this.handleShowModal(false)
      this.$emit('close')
    },
    async getListPatient (limit) {
      let self = this
      //   self.loading_persons = true
      let params = {
        page_num: 1,
        page_size: 1000,
        ws_id: this.$clinicId,
        order: 'desc',
        sort: 'updated_at'
      }
      //   if (self.search_person) {
      //     params.search = self.search_person
      //   }
      try {
        let resp = await self.$rf
          .getRequest('DoctorRequest')
          .getListPatientInWs(params)
        self.listPatients = resp.data?.data
      } catch (e) {
        // statements
        console.log(e)
      } finally {
        // self.loading_persons = false
      }
    },
    handleSelectBed (item) {
      if (!this.selectedBed) this.selectedBed = item.id
      else if (this.selectedBed === item.id) this.selectedBed = ''
      else this.selectedBed = item.id
    },
    async getListEmptyBed () {
      try {
        let from_date = window
          .moment(this.params?.from_date)
          .format('YYYY-MM-DD')
        let to_date = window.moment(this.params?.to_date).format('YYYY-MM-DD')
        this.loading_bed = true
        const params = {
          filter: 'empty',
          page_num: 1,
          page_size: 100,
          workspace_id: this.$globalClinic?.id,
          from: `${from_date} ${this.params?.from_time}`,
          to: `${to_date} ${this.params?.to_time}`
        }
        const res = await this.$rf
          .getRequest('DoctorRequest')
          .getListBedSlots(params)
        this.listBeds = res.data?.data || []
        this.totalBeds = res.data?.page?.total
      } catch (error) {
        console.log(error)
      } finally {
        this.loading_bed = false
      }
    },
    formatDate (date) {
      return window.moment(date).format('DD/MM/YYYY')
    },
    async handleSave () {
      if (!this.patient) {
        this.$message.error('Bạn cần chọn bệnh nhân')
        return
      }
      if (
        !this.params.from_time ||
        !this.params.to_time ||
        !this.params.to_date ||
        !this.params.from_date
      ) {
        this.$message.error('Bạn cần chọn thời gian')
        return
      }
      if (!this.selectedBed) {
        this.$message.error('Bạn cần chọn giường')
        return
      }
      if (this.type === '') {
        let selectedBed = this.listBeds?.find(
          elm => elm.id === this.selectedBed
        )
        this.$confirm(
          `Bạn có chắc chắn muốn đăng ký <strong>Giường: ${selectedBed.name} - Phòng: ${selectedBed.name_room}</strong> cho bệnh nhân <strong>${this.patientInfo?.name}</strong> không?`,
          'Xác nhận đăng ký giường',
          {
            confirmButtonText: 'Xác nhận',
            dangerouslyUseHTMLString: true
          }
        )
          .then(async () => {
            this.registerBed()
          })
          .catch(_ => {})
      }
      if (this.type === 'change') {
        let selectedBed = this.listBeds?.find(
          elm => elm.id === this.selectedBed
        )
        this.$confirm(
          `Bạn có chắc chắn muốn chuyển giường cho bệnh nhân <strong>${this.patientInfo?.name}</strong> từ <strong>Giường: ${this.bedInfo?.bed?.name} - Phòng: ${this.bedInfo?.room?.name}</strong> sang <strong>Giường: ${selectedBed.name} - Phòng: ${selectedBed.name_room}</strong> không?`,
          'Xác nhận chuyển giường',
          {
            confirmButtonText: 'Xác nhận',
            dangerouslyUseHTMLString: true
          }
        )
          .then(async () => {
            this.changeBed()
          })
          .catch(_ => {})
      }
    },
    async registerBed () {
      try {
        let from_date = window
          .moment(this.params?.from_date)
          .format('YYYY-MM-DD')
        let to_date = window.moment(this.params?.to_date).format('YYYY-MM-DD')
        this.isLoading = true
        const params = {
          bed_id: this.selectedBed || 0,
          date_in: `${from_date} ${this.params?.from_time}`,
          date_out: `${to_date} ${this.params?.to_time}`,
          emr_id: this.patientInfo?.emr_id || 0,
          patient_id: this.patientInfo?.id || 0,
          room_id: this.room_id,
          status: 2,
          workspace_id: this.$clinicId
        }
        const res = await this.$rf
          .getRequest('DoctorRequest')
          .createBedSlot(params)
        if (res) {
          this.handleClose()
          this.$emit('create')
        }
      } catch (error) {
        console.log(error)
      } finally {
        this.isLoading = false
      }
    },
    async changeBed () {
      try {
        let from_date = window
          .moment(this.params?.from_date)
          .format('YYYY-MM-DD')
        let to_date = window.moment(this.params?.to_date).format('YYYY-MM-DD')
        this.isLoading = true
        const params = {
          actual_out: Number(
            new Date()
              .getTime()
              ?.toString()
              ?.substring(0, 10)
          ),
          bed_id: this.selectedBed,
          date_in: `${from_date} ${this.params?.from_time}`,
          date_out: `${to_date} ${this.params?.to_time}`,
          emr_id: this.patientInfo?.emr_id || 0,
          patient_id: this.patientInfo?.id || 0,
          room_id: this.room_id,
          status: 2,
          workspace_id: this.$clinicId,
          previous_reservation_id: this.bedInfo.ID
        }
        const res = await this.$rf.getRequest('DoctorRequest').changeBed(params)
        if (res) {
          this.handleClose()
          this.$emit('change', res.data?.data?.ID)
        }
      } catch (error) {
        console.log(error)
      } finally {
        this.isLoading = false
      }
    },
    roundedTime (timeString) {
      let hour = timeString.slice(0, 2)
      let minutes = timeString.slice(3, 5)
      if (+minutes > 0 && +minutes < 30) {
        minutes = '30'
      } else if (+minutes > 30 && +minutes < 60) {
        minutes = '00'
        if (+hour === 23) hour = '00'
        else hour++
      }
      return `${hour}:${minutes}`
    },
    add4Hours (from_time) {
      let hour = from_time?.slice(0, 2)
      let minutes = from_time?.slice(3, 5)
      if (+hour >= 20 && +hour < 24) {
        hour = '0' + String(+hour + 4 - 24)
        if (this.params.from_date) {
          this.params.to_date = new Date(
            new Date(this.params.from_date).getTime() + 24 * 3600 * 1000
          )
        } else this.params.to_date = ''
      } else hour = +hour + 4
      return `${hour}:${minutes}`
    }
  }
}
</script>
  
<style lang="scss" scoped>
::v-deep {
  .el-dialog {
    background-color: #fcfcfd;
    .el-dialog__body {
      padding-top: 0;
    }
  }
  .modal-body {
    border-top: 1px solid #dbdbdb;
    color: #000;
    height: 100vh;
    max-height: unset;
    .vs__search {
      font-size: 14px;
      line-height: 20px;
    }
    .vs__selected {
      font-size: 14px;
      line-height: 20px;
    }
    .vs__dropdown-toggle {
      background-color: #f3f9ff;
    }
    .patient-info {
      .patient-info-content {
        border-bottom: 1px solid #dbdbdb;
        padding: 16px 0;
        .list-bed {
          display: grid;
          grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
          align-items: center;
          gap: 12px;
          .room-bed {
            padding: 12px;
            border: 1px solid #20419b;
            border-radius: 8px;
            text-align: center;
            transition: all ease-in-out 0.3s;
            .bed {
              color: #20419b;
            }
            &:hover {
              cursor: pointer;
              background: #f3f9ff;
            }
          }
          .selected {
            background: #20419b;
            color: #fff;
            .bed {
              color: #fff !important;
            }
            &:hover {
              background: #20419b;
            }
          }
        }
      }
    }
  }
}
</style>