<template>
  <div>
    <el-dialog
      class="cs-dialog"
      fullscreen
      :visible.sync="dialogVisible"
      :before-close="handleClose"
      append-to-body
    >
      <template slot="title">
        <h3 class="text-black">Chi tiết lịch xếp giường</h3>
      </template>
      <div class="modal-body" v-loading="isLoading">
        <div class="flex items-center justify-between">
          <div class="flex items-center gap-2">
            <h3 class="mb-0 font-normal">
              Phòng:
              <span class="font-semibold">{{ detail && detail.room && detail.room.name }}</span>
            </h3>
            <div class="dot"></div>
            <h3 class="mb-0 font-normal">
              Giường:
              <span class="font-semibold">{{ detail && detail.bed && detail.bed.name }}</span>
            </h3>
          </div>
          <div>
            <div class="status" :class="checkStatusClass">{{ checkStatus }}</div>
          </div>
        </div>
        <div class="grid grid-cols-2 gap-3 mt-3">
          <div class="info patient-info">
            <h2 class="mb-2">Thông tin người bệnh</h2>
            <p>Tên bệnh nhân: {{ detail && detail.persons && detail.persons.name }}</p>
            <p>Mã điều trị: {{ detail && detail.emr_data && detail.emr_data.ref_id }}</p>
            <p>Ngày sinh: {{ detail && detail.persons && formatDate(detail.persons.birthday) }}</p>
            <p
              class="mb-0"
            >Tên bệnh án: {{ detail && detail.emr_data && detail.emr_data.medical_record_name }}</p>
          </div>
          <div class="info patient-info">
            <h2 class="mb-2">Thông tin lịch xếp giường</h2>
            <p>Loại giường: {{ detail && detail.bed && detail.bed.name }} - {{ detail && detail.bed && detail.bed.type_bed }}</p>
            <p>Phòng: {{ detail && detail.room && detail.room.name }}</p>
            <p>Giờ vào: {{ detail && detail.date_in && formatDateTime(detail.date_in) }}</p>
            <p>Giờ ra: {{ detail && detail.date_out && formatDateTime(detail.date_out) }}</p>
          </div>
        </div>
        <div class="grid grid-cols-4 gap-3 mt-3">
          <el-button
            type="primary"
            :disabled="detail.status !== BED_STATUS.RECEIVED"
            :loading="loading_btn"
            @click="handleNote"
          >+ Tạo ghi chú</el-button>
          <el-button
            type="primary"
            class="ml-0"
            plain
            @click="handleCheckout"
            :loading="loading_btn"
            :disabled="detail.status !== BED_STATUS.RECEIVED"
          >Ra viện</el-button>
          <el-button
            type="primary"
            class="ml-0"
            plain
            @click="handleChangeBed"
            :loading="loading_btn"
            :disabled="detail.status !== BED_STATUS.RECEIVED"
          >Chuyển giường</el-button>
          <el-button
            type="danger"
            class="ml-0"
            plain
            @click="handleCancelBed"
            :loading="loading_btn"
            :disabled="detail.status !== BED_STATUS.RECEIVED"
          >Huỷ giường</el-button>
        </div>
        <div class="mt-3" v-if="isShowCreateNote">
          <label class="mb-1">Ghi chú</label>
          <el-input type="textarea" :rows="4" placeholder="Nhập ghi chú" v-model="note"></el-input>
          <div class="flex justify-end mt-2 w-100">
            <el-button
              type="primary"
              :disabled="!note"
              :loading="loading_btn"
              @click="handleCreateNote"
            >Tạo</el-button>
          </div>
        </div>
        <div class="mt-3">
          <h3>Nhật ký hoạt động</h3>
          <el-card v-if="listAction.length" v-loading="isLoadingHistory">
            <div class="list-action" v-for="item in listAction" :key="item.ID">
              <div class="flex flex-col gap-1 items-center">
                <div
                  class="action-bg"
                  :class="item.status === BED_STATUS.SWITCH_BED ? 'rotate-90' : ''"
                >
                  <i class="el-icon-edit" v-if="item.status === BED_STATUS.NOTE"></i>
                  <i
                    class="el-icon-check"
                    v-if="item.status === BED_STATUS.RECEIVED || item.status === BED_STATUS.DISCHARGED"
                  ></i>
                  <i class="el-icon-plus" v-if="item.status === BED_STATUS.NEXT_BED"></i>
                  <i class="el-icon-sort" v-if="item.status === BED_STATUS.SWITCH_BED"></i>
                  <i class="el-icon-close" v-if="item.status === BED_STATUS.CANCEL"></i>
                </div>
                <div class="divider h-100 w-0"></div>
              </div>
              <div class="w-100">
                <p class="font-semibold mb-1">{{ getContent(item) }}</p>
                <div class="note" v-if="item.status === BED_STATUS.NOTE">{{ item.content }}</div>
                <small>
                  bởi
                  <b>{{item.created_user && item.created_user.name}}</b> vào
                  <b>{{formatDateTime(item.created_at)}}</b>
                </small>
              </div>
            </div>
          </el-card>
          <div v-else class="mt-3">Không có dữ liệu</div>
        </div>
      </div>
    </el-dialog>
    <modal-register-room
      ref="ModalRegisterRoom"
      @change="handleChangeSuccess"
      @close="getDetailReservation($route.query.id)"
    ></modal-register-room>
  </div>
</template>
    
    <script>
// import appUtils from '../../utils/appUtils'
import { mixinQueryParams } from '@/utils/mixinQueryParams'
import vSelect from 'vue-select'
import { BED_STATUS } from '@/utils/constants'
import ModalRegisterRoom from './ModalRegisterRoom.vue'

export default {
  name: 'ModalDetail',
  mixins: [mixinQueryParams],
  components: { vSelect, ModalRegisterRoom },
  data () {
    return {
      dialogVisible: false,
      isLoading: false,
      detail: {},
      BED_STATUS,
      loading_btn: false,
      note: '',
      isShowCreateNote: false,
      listAction: [],
      isLoadingHistory: false
    }
  },
  computed: {
    checkStatus () {
      if (this.detail.status === BED_STATUS.RECEIVED) return 'Đã tiếp nhận'
      if (this.detail.status === BED_STATUS.SWITCH_BED) {
        return 'Đã chuyển giường'
      }
      if (this.detail.status === BED_STATUS.CANCEL) return 'Đã huỷ'
      if (this.detail.status === BED_STATUS.DISCHARGED) return 'Đã ra viện'
    },
    checkStatusClass () {
      if (this.detail.status === BED_STATUS.RECEIVED) return 'received'
      if (this.detail.status === BED_STATUS.SWITCH_BED) return 'switch'
      if (this.detail.status === BED_STATUS.CANCEL) return 'cancel'
      if (this.detail.status === BED_STATUS.DISCHARGED) return 'discharged'
    }
  },
  watch: {
    '$route.query.id' (value) {
      if (value) {
        this.getDetailReservation(value)
        this.getHistory(this.$route.query.id)
      }
    }
  },
  mounted () {
    if (this.$route.query.id) {
      this.getDetailReservation(this.$route.query.id)
      this.getHistory(this.$route.query.id)
    }
  },
  methods: {
    handleShowModal (isShow) {
      this.dialogVisible = isShow
      //   if (!isShow) return

      //   this.getListPatient()
    },
    handleClose () {
      this.isShowCreateNote = false
      this.note = ''
      this.handleShowModal(false)
      this.handleSetFilter({ id: null })
      this.$emit('close')
    },
    formatDate (date) {
      return window.moment(date).format('DD/MM/YYYY')
    },
    formatDateTime (date) {
      return window.moment(date).format('HH:mm DD/MM/YYYY')
    },
    async getDetailReservation (id) {
      try {
        this.isLoading = true
        const res = await this.$rf
          .getRequest('DoctorRequest')
          .getDetailReservation(id)
        this.detail = res?.data?.data
      } catch (error) {
        console.log(error)
      } finally {
        this.isLoading = false
      }
    },
    async handleCheckout () {
      try {
        const params = {
          actual_out: Number(
            new Date()
              .getTime()
              ?.toString()
              ?.substring(0, 10)
          ),
          status: BED_STATUS.DISCHARGED
        }
        this.$confirm(
          `Bạn có chắc chắn muốn cho bệnh nhân <strong>${this.detail?.persons?.name}</strong> ra viện không?`,
          'Xác nhận ra viện',
          {
            confirmButtonText: 'Xác nhận',
            dangerouslyUseHTMLString: true
          }
        )
          .then(async () => {
            this.loading_btn = true
            const res = await this.$rf
              .getRequest('DoctorRequest')
              .checkout(this.$route.query.id, params)
            if (res) {
              this.handleClose()
              this.$toast.open({
                message: 'Cho bệnh nhân ra viện thành công',
                type: 'success'
              })
              this.$emit('success')
              this.loading_btn = false
            }
          })
          .catch(_ => {})
      } catch (error) {
        console.log(error)
      } finally {
        this.loading_btn = false
      }
    },
    handleChangeBed () {
      this.$refs.ModalRegisterRoom.handleShowModal(true, this.detail)
    },
    handleCancelBed () {
      try {
        const params = {
          actual_out: Number(
            new Date()
              .getTime()
              ?.toString()
              ?.substring(0, 10)
          ),
          status: BED_STATUS.CANCEL,
          discharge_reason: ''
        }
        this.$confirm(
          `Bạn có chắc chắn muốn huỷ <strong>Giường: ${this.detail?.bed?.name} - Phòng: ${this.detail?.room?.name}</strong> không?`,
          'Xác nhận huỷ giường',
          {
            confirmButtonText: 'Xác nhận',
            dangerouslyUseHTMLString: true
          }
        )
          .then(async () => {
            this.loading_btn = true
            const res = await this.$rf
              .getRequest('DoctorRequest')
              .cancelBed(this.$route.query.id, params)
            if (res) {
              // this.handleClose()
              this.$toast.open({
                message: 'Huỷ giường thành công',
                type: 'success'
              })
              this.getDetailReservation(this.$route.query.id)
              this.loading_btn = false
            }
          })
          .catch(_ => {})
      } catch (error) {
        console.log(error)
      } finally {
        this.loading_btn = false
      }
    },
    handleChangeSuccess (id) {
      this.loading_btn = false
      this.$message.success('Đổi giường cho bệnh nhân thành công')
      this.handleSetFilter({ id: id })
      this.getDetailReservation(id)
    },
    handleNote () {
      this.isShowCreateNote = !this.isShowCreateNote
    },
    async handleCreateNote () {
      try {
        this.loading_btn = true
        const params = {
          content: this.note,
          reservation_id: +this.$route.query?.id,
          status: BED_STATUS.NOTE
        }
        const res = await this.$rf
          .getRequest('DoctorRequest')
          .createNote(params)
        if (res) {
          this.$message.success('Tạo chi chú thành công')
          this.getHistory()
          this.isShowCreateNote = false
          this.note = ''
        }
      } catch (error) {
        this.$message.error('Có lỗi xảy ra, vui lòng thử lại')
        console.log(error)
      } finally {
        this.loading_btn = false
      }
    },
    async getHistory (id) {
      try {
        this.isLoadingHistory = true
        const res = await this.$rf.getRequest('DoctorRequest').getListNotes({
          reservation_id: id
        })
        this.listAction = res?.data?.data
      } catch (error) {
        console.log(error)
      } finally {
        this.isLoadingHistory = false
      }
    },
    getContent (item) {
      switch (item.status) {
        case BED_STATUS.RECEIVED:
          return 'Đăng ký giường'
        case BED_STATUS.CANCEL:
          return 'Huỷ giường'
        case BED_STATUS.DISCHARGED:
          return 'Ra viện'
        case BED_STATUS.NOTE:
          return 'Ghi chú'
        case BED_STATUS.SWITCH_BED:
          return 'Chuyển giường'
        case BED_STATUS.NEXT_BED:
          return `Chuyển từ giường ${item.content}`

        default:
          return 'Đăng ký giường'
      }
    }
  }
}
</script>
    
  <style lang="scss" scoped>
::v-deep {
  .el-dialog {
    background-color: #fcfcfd;
    .el-dialog__body {
      padding-top: 0;
    }
  }
  .modal-body {
    border-top: 1px solid #dbdbdb;
    color: #000;
    height: 100vh;
    max-height: unset;
    .dot {
      width: 8px;
      height: 8px;
      background: #dbdbdb;
      border-radius: 50%;
    }
    .info {
      padding: 12px 16px;
      background: #f9f9f9;
      border-radius: 8px;
      border: 1px solid #dbdbdb;
    }
    .status {
      padding: 8px 12px;
      border-radius: 4px;
      text-align: center;
      font-weight: 500;
    }
    .cancel {
      color: #d92d20;
      border: 1px solid #d92d20;
    }
    .discharged {
      color: #039855;
      border: 1px solid #039855;
    }
    .received {
      color: #20409b;
      border: 1px solid #20409b;
    }
    .switch {
      color: #f49500;
      border: 1px solid #f49500;
    }
    .divider {
      border-right: 1px solid #98a2b3;
    }
    .action-bg {
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      background: #d0d5dd;
      width: 24px;
      min-height: 24px;
      max-height: 24px;
    }
    .note {
      padding: 8px 12px;
      border: 1px solid #dbdbdb;
      border-radius: 4px;
      font-size: 13px;
      line-height: 20px;
    }
    .rotate-90 {
      transform: rotate(90deg);
    }
    .list-action {
      display: flex;
      gap: 12px;
      margin-bottom: 12px;
      min-height: 60px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
</style>