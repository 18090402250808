<template>
  <div>
    <div class="modal fade" id="modal__show_list_patient_in_room">
      <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">{{ $t('Danh sách Bệnh nhân trong phòng') }}</h5>
            <button
              type="button"
              class="close text-white bg-transparent border-0 mr-2"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true" class="fs-30 fw-500">&times;</span>
            </button>
          </div>
          <div v-loading="loading" class="modal-body txt-grey-900">
            <div class="mb-3 text-center">
              <div style="color: #20419B;" class="font-bold text-xl">{{ $globalClinic.name || '' }}</div>
            </div>

            <div class="table-responsive">
              <table class="w-full table-bordered align-middle m-0">
                <thead>
                  <tr>
                    <th rowspan="2" class="align-middle text-center py-1 px-2 w-1/4">
                      <span>{{ $t('Phòng') }}</span>
                    </th>
                    <th rowspan="2" class="align-middle text-center py-1 px-2 w-1/4">
                      <span>{{ $t('Giường') }}</span>
                    </th>
                    <th colspan="2" class="align-middle text-center py-1 px-2 w-1/2">
                      <span
                        v-if="dateFilters.fromDate.toString() === dateFilters.toDate.toString()"
                      >{{ appUtils.formatDate(dateFilters.fromDate) }}</span>
                      <template v-else>
                        <span>{{ appUtils.formatDate(dateFilters.fromDate) }}</span>
                        <span class="mx-2">-</span>
                        <span>{{ appUtils.formatDate(dateFilters.toDate) }}</span>
                      </template>
                    </th>
                  </tr>
                  <tr>
                    <th class="align-middle text-center py-1 px-2 w-1/4">
                      <span>Sáng</span>
                    </th>
                    <th class="align-middle text-center py-1 px-2 w-1/4">
                      <span>Chiều</span>
                    </th>
                  </tr>
                </thead>
                <tbody
                  v-for="(room, index) in dataRooms"
                  :key="`room-${room.ID}-${index}`"
                  :data-key="`room-${room.ID}-${index}-${actionKey}`"
                >
                  <template v-for="(bed, idx) in room.listBeds">
                    <tr
                      v-for="(patient, pIdx) in bed.barList"
                      :key="`patient-${patient.id}-${pIdx}-${actionKey}`"
                      :set="amPmData = checkAMorPM(patient)"
                    >
                      <td
                        v-if="calculateRoomRowSpan(room) > 1 && pIdx === 0 && idx === 0"
                        class="align-middle text-center py-1 px-2"
                        :rowspan="calculateRoomRowSpan(room)"
                      >
                        <span>{{ room.name }}</span>
                      </td>
                      <td
                        v-else-if="calculateRoomRowSpan(room) === 1"
                        class="align-middle text-center py-1 px-2"
                      >
                        <span>{{ room.name }}</span>
                      </td>

                      <td
                        class="align-middle text-center py-1 px-2"
                        v-if="bed.barList.length > 1 && pIdx === 0"
                        :rowspan="bed.barList.length"
                      >
                        <span class="whitespace-nowrap">{{ bed.label }}</span>
                      </td>
                      <td
                        v-else-if="bed.barList.length === 1"
                        class="align-middle text-center py-1 px-2"
                      >
                        <span class="whitespace-nowrap">{{ bed.label }}</span>
                      </td>

                      <template v-for="(data, amPmIdx) in amPmData">
                        <template
                          v-if="data.amData && data.pmData && data.amData.id === data.pmData.id"
                        >
                          <td
                            class="align-middle py-1 px-2"
                            :key="`am-${data.amData.label}-${amPmIdx}-colspan`"
                            colspan="2"
                          >
                            <div class="whitespace-nowrap">
                              <div
                                class="text-uppercase font-bold border-b-2 border-l-0 border-t-0 border-r-0 border-solid border-gray-200 pb-0.5 mb-0.5"
                              >{{ formatterName(data.amData)  }}</div>
                              <div>
                                <span class="block">
                                  <b>{{ $t('Từ') }}:</b>
                                  {{ appUtils.formatDateTime(data.amData.myStart) }}
                                </span>
                                <!-- <span class="mx-2">-</span> -->
                                <span>
                                  <b>{{ $t('Đến') }}:</b>
                                  {{ appUtils.formatDateTime(data.amData.myEnd) }}
                                </span>
                              </div>
                            </div>
                          </td>
                        </template>

                        <template v-else>
                          <template>
                            <td
                              class="align-middle py-1 px-2"
                              :key="`am-${data.amData.label}-${amPmIdx}`"
                              v-if="data.amData && !data.pmData"
                            >
                              <div class="text-capitalize whitespace-nowrap">
                                <div
                                  class="text-uppercase font-bold border-b-2 border-l-0 border-t-0 border-r-0 border-solid border-gray-200 pb-0.5 mb-0.5"
                                >{{ formatterName(data.amData) }}</div>
                                <div>
                                  <span class="block">
                                    <b>{{ $t('Từ') }}:</b>
                                    {{ appUtils.formatDateTime(data.amData.myStart) }}
                                  </span>
                                  <!-- <span class="mx-2">-</span> -->
                                  <span>
                                    <b>{{ $t('Đến') }}:</b>
                                    {{ appUtils.formatDateTime(data.amData.myEnd) }}
                                  </span>
                                </div>
                              </div>
                            </td>
                            <td class="align-middle py-1 px-2" v-else :key="`am-${amPmIdx}`"></td>
                          </template>

                          <template>
                            <td
                              class="align-middle py-1 px-2"
                              :key="`pm-${data.pmData.label}-${amPmIdx}`"
                              v-if="!data.amData && data.pmData"
                            >
                              <div class="text-capitalize whitespace-nowrap">
                                <div
                                  class="text-uppercase font-bold border-b-2 border-l-0 border-t-0 border-r-0 border-solid border-gray-200 pb-0.5 mb-0.5"
                                >{{ formatterName(data.amData) }}</div>
                                <div>
                                  <span class="block">
                                    <b>{{ $t('Từ') }}:</b>
                                    {{ appUtils.formatDateTime(data.pmData.myStart) }}
                                  </span>
                                  <!-- <span class="mx-2">-</span> -->
                                  <span>
                                    <b>{{ $t('Đến') }}:</b>
                                    {{ appUtils.formatDateTime(data.pmData.myEnd) }}
                                  </span>
                                </div>
                              </div>
                            </td>
                            <td class="align-middle py-1 px-2" v-else :key="`pm-${amPmIdx}`"></td>
                          </template>
                        </template>
                      </template>
                    </tr>
                  </template>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import appUtils from '@/utils/appUtils'

export default {
  name: 'ModalListPatientInRoom',
  components: {},
  props: {
    roomGroups: {
      type: Array,
      default: []
    },
    actionKey: Number,
    dateFilters: Object
  },
  data () {
    return {
      loading: true,
      dataRooms: [],
      appUtils,
      rooms: [
        {
          name: 'Room 1',
          beds: [
            {
              name: 'Bed 1',
              patients: [
                {
                  name: 'John Doe 1',
                  start: '08:00 22/11/2023',
                  end: '10:00 22/11/2023'
                },
                {
                  name: 'John Doe 2',
                  start: '13:00 22/11/2023',
                  end: '16:00 22/11/2023'
                }
              ]
            },
            {
              name: 'Bed 2',
              patients: [
                {
                  name: 'John Doe 3',
                  start: '06:15 22/11/2023',
                  end: '10:15 22/11/2023'
                },
                {
                  name: 'John Doe 4',
                  start: '12:00 22/11/2023',
                  end: '18:30 22/11/2023'
                }
              ]
            }
          ]
        },
        {
          name: 'Room 2',
          beds: [
            {
              name: 'Bed 1',
              patients: [
                {
                  name: 'Jenny 1',
                  start: '09:10 22/11/2023',
                  end: '13:30 22/11/2023'
                },
                {
                  name: 'Jenny 2',
                  start: '14:00 22/11/2023',
                  end: '18:00 22/11/2023'
                }
              ]
            },
            {
              name: 'Bed 2',
              patients: [
                {
                  name: 'Jenny 3',
                  start: '10:15 22/11/2023',
                  end: '17:40 22/11/2023'
                }
              ]
            }
          ]
        }
      ]
    }
  },
  watch: {
    roomGroups: {
      handler (data) {
        this.dataRooms = [...data]
      },
      deep: true,
      immediate: true
    }
  },
  beforeMount () {
    const self = this
    self.dataRooms = [...self.roomGroups]
  },
  mounted () {
    const self = this
    self.loading = false
    self.$nextTick(function () {
      window
        .$('#modal__show_list_patient_in_room')
        .on('hidden.bs.modal', function (e) {})
    })
  },
  methods: {
    calculateRoomRowSpan (room) {
      let rowSpan = 0
      room.listBeds.forEach(bed => {
        rowSpan += bed.barList.length
      })
      return rowSpan
    },
    checkAMorPM (data) {
      const format = 'YYYY-MM-DD HH:mm'
      // const selectedDateTime = window.moment(
      //   this.dateFilters.fromDate,
      //   'DD/MM/YYYY'
      // )

      const amData = []
      const pmData = []

      const startTime = window.moment(data.myStart, format)
      const endTime = window.moment(data.myEnd, format)

      const dataRaw = {
        ...data
      }

      if (startTime.hour() < 12 && endTime.hour() < 12) {
        amData.push(dataRaw)
      } else if (startTime.hour() >= 12 && endTime.hour() >= 12) {
        pmData.push(dataRaw)
      } else if (startTime.hour() < 12 && endTime.hour() >= 12) {
        amData.push(dataRaw)
        pmData.push(dataRaw)
      } else if (
        window.moment(data.myEnd).valueOf() >
          window
            .moment(this.dateFilters.fromDate)
            .endOf('day')
            .valueOf() &&
        window.moment(data.myEnd).format('DD-MM-YYYY') !==
          window.moment(data.myStart).format('DD-MM-YYYY')
      ) {
        pmData.push(dataRaw)
      }

      const result = []
      for (let i = 0; i < Math.max(amData.length, pmData.length); i++) {
        result.push({
          amData: amData[i] || null,
          pmData: pmData[i] || null
        })
      }

      return result
    },
    formatterName (data) {
      if (data?.medical_record_id && data?.patient_id && data?.label) {
        return data?.label?.toLowerCase()
      } else {
        return 'Bảo trì'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep {
}
</style>
