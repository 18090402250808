<template>
  <div>
    <filter-room @register="handleOpenPopupRegister" class="mb-4"></filter-room>
    <div v-loading="isLoading">
      <el-table
        :data="data"
        style="width: 100%"
        @row-click="handleOpenModalDetail"
        row-class-name="cursor-pointer"
      >
        <el-table-column prop="name" label="Tên bệnh nhân"></el-table-column>
        <el-table-column prop="ref_id" label="Mã điều trị" width="130"></el-table-column>
        <el-table-column prop="medical_record_name" label="Tên bệnh án"></el-table-column>
        <el-table-column prop="name_bed" label="Tên giường" width="120"></el-table-column>
        <el-table-column prop="room_name" label="Tên phòng" width="120"></el-table-column>
        <el-table-column prop="status" label="Trạng thái" width="120">
          <template slot-scope="scope">
            <div class="status" :class="checkStatusClass(scope.row)">{{ checkStatus(scope.row) }}</div>
          </template>
        </el-table-column>
        <el-table-column prop="date_in" label="Thời gian vào" width="140">
          <template slot-scope="scope">
            <span>{{scope.row.date_in && formatDateTime(scope.row.date_in)}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="date_out" label="Thời gian ra (dự kiến)" width="140">
          <template slot-scope="scope">
            <span>{{scope.row.date_out && formatDateTime(scope.row.date_out)}}</span>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="flex items-center justify-end mt-3">
      <el-pagination
        v-if="data.length"
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="+$route.query.page_num || pagination.page_num"
        :page-sizes="[10, 20, 30, 50]"
        :page-size="pagination.page_size"
        layout="sizes, prev, pager, next"
        :total="pagination.total"
      ></el-pagination>
    </div>
    <modal-register-room
      ref="ModalRegisterRoom"
      @create="handleCreateSuccess"
      @close="getListBookedBed"
    ></modal-register-room>
    <modal-detail ref="ModalDetail" @success="handleActionSuccess" @close="getListBookedBed"></modal-detail>
  </div>
</template>
<script>
import ModalRegisterRoom from './ModalRegisterRoom.vue'
import ModalDetail from './ModalDetail.vue'
import FilterRoom from './FilterRoom.vue'
import appUtils from '../../../utils/appUtils'
import { mixinQueryParams } from '@/utils/mixinQueryParams'
import { BED_STATUS } from '@/utils/constants'

export default {
  name: 'BedList',
  components: { FilterRoom, ModalRegisterRoom, ModalDetail },
  mixins: [mixinQueryParams],
  data () {
    return {
      data: [],
      isLoading: false,
      pagination: {
        page_num: 1,
        page_size: 10,
        total: 0
      },
      BED_STATUS
    }
  },
  watch: {
    '$route.query.keyword' () {
      this.pagination.page_num = 1
      this.handleSetFilter({
        page_num: this.pagination.page_num,
        page_size: this.pagination.page_size
      })
      this.getListBookedBed()
    },
    '$route.query.status' () {
      this.pagination.page_num = 1
      this.handleSetFilter({
        page_num: this.pagination.page_num,
        page_size: this.pagination.page_size
      })
      this.getListBookedBed()
    },
    '$route.query.from' () {
      this.pagination.page_num = 1
      this.handleSetFilter({
        page_num: this.pagination.page_num,
        page_size: this.pagination.page_size
      })
      this.getListBookedBed()
    },
    '$route.query.tab' (value) {
      if (+value === 2) {
        if (this.$route.query.page_size) {
          this.pagination.page_size = +this.$route.query.page_size
        }
        if (this.$route.query.page_num) {
          this.pagination.page_num = +this.$route.query.page_num
        }
        this.getListBookedBed()
      }
    }
  },
  mounted () {
    if (this.$route.query.page_size) {
      this.pagination.page_size = +this.$route.query.page_size
    }
    if (this.$route.query.page_num) {
      this.pagination.page_num = +this.$route.query.page_num
    }
    this.getListBookedBed()
    if (this.$route.query.id) {
      this.$refs.ModalDetail.handleShowModal(true)
    }
  },
  methods: {
    handleOpenPopupRegister () {
      this.$refs.ModalRegisterRoom.handleShowModal(true)
    },
    async getListBookedBed () {
      try {
        this.isLoading = true
        const listLocations = await this.getListFloors()
        const params = {
          ws_id: this.$globalClinic?.id,
          page_num: +this.$route.query.page_num || this.pagination.page_num,
          page_size: +this.$route.query.page_size || this.pagination.page_size,
          loc_ids: listLocations,
          order: 'desc',
          sort_by: 'created_at',
          keyword: this.$route.query.keyword || null,
          status: this.$route.query.status || '',
          from: this.$route.query.from || null,
          to: this.$route.query.to || null
        }
        const res = await this.$rf
          .getRequest('DoctorRequest')
          .getListBookedBed(params)
        this.data = res.data?.data
        this.pagination.total = res.data?.page.total
      } catch (error) {
        console.log(error)
      } finally {
        this.isLoading = false
      }
    },
    async getListFloors () {
      try {
        const res = await this.$rf.getRequest('DoctorRequest').getListFloors({
          ws_id: this.$globalClinic?.id,
          page_size: 1000,
          page_num: 1
        })
        const listLocations = res.data?.data?.map(elm => elm.id)?.toString()
        return listLocations
      } catch (error) {
        console.log(error)
      }
    },
    handleCreateSuccess () {
      this.$toast.open({
        message: 'Tạo lịch thành công',
        type: 'success'
      })
      this.getListBookedBed()
    },
    formatDateTime (date) {
      return appUtils.formatDateTime(date)
    },
    handleSizeChange (size) {
      this.pagination.page_size = size
      this.pagination.page_num = 1
      this.handleSetFilter({
        page_num: this.pagination.page_num,
        page_size: this.pagination.page_size
      })
      this.getListBookedBed()
    },
    handleCurrentChange (page) {
      this.pagination.page_num = page
      this.handleSetFilter({
        page_num: this.pagination.page_num,
        page_size: this.pagination.page_size
      })
      this.getListBookedBed()
    },
    handleOpenModalDetail (item) {
      this.$refs.ModalDetail.handleShowModal(true)
      this.handleSetFilter({ id: item.ID })
    },
    handleActionSuccess () {
      this.getListBookedBed()
    },
    checkStatus (item) {
      if (item.status === BED_STATUS.RECEIVED) return 'Đã tiếp nhận'
      if (item.status === BED_STATUS.SWITCH_BED) return 'Đã chuyển giường'
      if (item.status === BED_STATUS.CANCEL) return 'Đã huỷ'
      if (item.status === BED_STATUS.DISCHARGED) return 'Đã ra viện'
    },
    checkStatusClass (item) {
      if (item.status === BED_STATUS.RECEIVED) return 'received'
      if (item.status === BED_STATUS.SWITCH_BED) return 'switch'
      if (item.status === BED_STATUS.CANCEL) return 'cancel'
      if (item.status === BED_STATUS.DISCHARGED) return 'discharged'
    }
  }
}
</script>
<style lang="scss" scoped>
::v-deep.el-table {
  .el-table__body {
    .status {
      padding: 8px 12px;
      border-radius: 4px;
      text-align: center;
      font-weight: 500;
    }
    .cancel {
      color: #d92d20;
      border: 1px solid #d92d20;
    }
    .discharged {
      color: #039855;
      border: 1px solid #039855;
    }
    .received {
      color: #20409b;
      border: 1px solid #20409b;
    }
    .switch {
      color: #f49500;
      border: 1px solid #f49500;
    }
  }
}
</style>