<template>
  <div>
    <div class="modal fade" id="modal__assign_person">
      <div class="modal-dialog modal-md modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">
              {{
                update
                  ? "Chi tiết thông tin giường"
                  : "Phân giường cho bệnh nhân"
              }}
            </h5>
          </div>
          <div v-loading="loading" class="modal-body txt-grey-900">
            <div class="row">
              <div class="col">
                <div style="color: #20419b" class="font-bold">
                  {{ $globalClinic.name || "" }}
                </div>
                <div style="color: #20419b" class="font-bold mt-2">
                  {{ dataRoom.name }} - {{ dataRoom.address }}
                </div>
                <div style="color: #20419b" class="mt-2">
                  {{ dataBed.type_bed }} - {{ dataBed.name }}
                </div>
                <div style="color: #20419b" class="mt-1">
                  {{ dataBed.price }} VNĐ/ngày
                </div>
              </div>
              <div class="col">
                <div class="flex">
                  <div class="flex mt-auto mb-1">
                    <label class="fs-14 fw-500">Bảo trì</label>
                    <el-switch v-model="isService" class="ml-2" size="large" :disabled="dataReserve.patient_id && dataReserve.medical_record_id" />
                  </div>
                  <qrcode-vue
                    class="ml-auto"
                    :value="dataRoom.name + '-' + dataBed.type_bed || ''"
                    :size="sizeQr"
                    level="H"
                  />
                </div>
              </div>
            </div>
            <div v-if="!isService" class="row">
              <div class="col-md-12 mt-6">
                <label class="fs-14 fw-500 required">Tên bệnh án</label>
                <el-select
                  v-model="selectedMedicalRecordId"
                  filterable
                  remote
                  :remote-method="handleFilterRemote"
                  @change="loadDataMedicalRecord"
                  class="w-full"
                  placeholder="Chọn bệnh án"
                  :loading="loading"
                  @visible-change="handleVisibleChange"
                >
                  <div
                    ref="infiniteScrollOptions"
                    v-infinite-scroll="handleInfiniteScroll"
                    :infinite-scroll-immediate="false"
                  >
                    <el-option
                      v-for="item in listPersonIncludingSelectedPerson"
                      :key="item.id + 'bn'"
                      :label="getLabel(item)"
                      :value="item.id"
                    ></el-option>
                    <div
                      v-show="scroll_loading"
                      class="text-center text-danger my-2"
                    >
                      <b-spinner class="align-middle"></b-spinner>
                    </div>
                  </div>
                </el-select>
              </div>
            </div>

            <div class="mt-6 row">
              <div class="col-md-6">
                <label class="fs-14 fw-500 required">Thời gian bắt đầu</label>
                <input
                  name="fromDate"
                  v-model="form.fromDate"
                  type="datetime-local"
                  class="el-input__inner pr-3 bg-white border border-gray-200"
                  :placeholder="$t('Chọn ngày')"
                  :max="maxDateValue"
                />
              </div>
              <div class="col-md-6">
                <label class="fs-14 fw-500 required">Thời gian kết thúc</label>
                <input
                  name="fromDate"
                  v-model="form.toDate"
                  type="datetime-local"
                  class="el-input__inner pr-3 bg-white border border-gray-200"
                  :placeholder="$t('Chọn ngày')"
                  :max="maxDateValue"
                />
              </div>
            </div>

            <div class="mt-6 text-sm font-medium flex justify-end">
              <button
                @click="confirmReservation"
                class="flex items-center justify-center border-0 focus:outline-none mx-3 bg-blue-700 text-white rounded-md h-9 px-3 py-2"
              >
                Lưu
              </button>
              <button
                data-dismiss="modal"
                @click="hideModalAssignPerson()"
                class="flex items-center justify-center border-0 focus:outline-none bg-blue-50 text-primary rounded-md h-9 px-3 py-2"
              >
                <div>Hủy</div>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { ButtonHoDo } from '@/components/Button'
import { RESERVATION_TYPE } from '../../../utils/constants'
import QrcodeVue from 'qrcode.vue'
import moment from 'moment'
import { debounce } from 'lodash'

export default {
  name: 'ModalAssignPerson',
  components: { ButtonHoDo, QrcodeVue },
  props: ['update', 'dataRoom', 'dataBed', 'dataReserve'],
  data () {
    return {
      loadingBadt: false,
      selectedMedicalRecord: {},
      selectedMedicalRecordId: null,
      listPerson: [],
      valueQr: 'https://example.com',
      sizeQr: 100,
      isService: false,
      loading: false,
      form: {
        name: '',
        address: '',
        status: '',
        price: '',
        fromDate: moment().format('YYYY-MM-DD HH:mm'),
        toDate: moment().add(4, 'hours').format('YYYY-MM-DD HH:mm')
      },
      form_update: {
        name: '',
        address: '',
        status: null
      },
      listPersonPage: 1,
      last_page: 10000,
      filter_search: '',
      scroll_loading: true
    }
  },
  watch: {
    dataReserve () {
      this.handleDataReserve()
    }
  },
  computed: {
    maxDateValue () {
      return this.isMaxDateToday ? new Date().toISOString().split('T')[0] : ''
    },
    listPersonIncludingSelectedPerson () {
      if (this.update) {
        return [
          this.selectedMedicalRecord,
          ...this.listPerson.filter(
            (person) => this.selectedMedicalRecordId !== person.id
          )
        ]
      }
      return this.listPerson
    }
  },
  mounted () {
    let self = this
    window.$(document).ready(function () {
      window.$('#modal__assign_person').on('hidden.bs.modal', function (e) {
        self.resetData()
      })
    })
    this.handleDataReserve()
    this.getBADT()
  },
  methods: {
    load () {
      this.pageCount += 1
      this.getBADT()
    },
    loadDataMedicalRecord () {
      this.selectedMedicalRecord = this.listPerson.find(
        (x) => x.id === this.selectedMedicalRecordId
      )
    },
    async getBADT (isFromSearch = false) {
      if (!this.$globalClinic && !this.$globalClinic.id) return
      let self = this
      const params = {
        clinic_id: this.$globalClinic.id,
        page: this.listPersonPage,
        sort: 'created_at',
        sort_by: 'desc',
        limit: 15,
        search: this.filter_search
      }
      const r = await this.$rf
        .getRequest('DoctorRequest')
        .getBADT(params)
        .then((r) => {
          if (r.data.current_page > r.data.last_page) {
            return r
          }
          this.last_page = r.data.last_page
          this.listPerson = isFromSearch
            ? r.data.data
            : [...this.listPerson, ...r.data.data]
        })
        .catch((e) => {
          console.log(e)
          self.$toast.open({
            message: 'BADT không tồn tại',
            type: 'error'
          })
          return false
        })
      return r
    },
    async getDetailBADT () {
      const res = await this.$rf
        .getRequest('DoctorRequest')
        .getDetailBADT(this.selectedMedicalRecordId)
      this.selectedMedicalRecord = res.data
    },
    async handleFilterRemote (search) {
      this.listPersonPage = 1
      this.filter_search = search
      const isFromSearch = true
      this.$nextTick(() => {
        this.$refs.infiniteScrollOptions.scrollIntoView({ behavior: 'smooth' })
      })
      await this.getBADT(isFromSearch)
    },
    handleInfiniteScroll: debounce(async function () {
      if (this.listPersonPage > this.last_page) {
        this.scroll_loading = false
        return
      }
      if (this.listPersonPage === Number(this.last_page)) {
        this.scroll_loading = false
      }
      this.listPersonPage++
      await this.getBADT()
    }, 500),
    handleDataReserve () {
      if (this.dataReserve && this.update) {
        let item = this.dataReserve
        this.selectedMedicalRecordId = item.medical_record_id
        this.form.fromDate = item.myStart
        this.form.toDate = item.myEnd
        this.form.id = item.id
        if (this.selectedMedicalRecordId) {
          this.getDetailBADT()
        }
        if (item.status === RESERVATION_TYPE.CODE.MAINTANCE && !item?.patient_id && !item?.medical_record_id) {
          this.isService = true
        }
      }
    },
    async handleVisibleChange (isShow) {
      if (!isShow) {
        this.listPersonPage = 1
        this.listPerson = []
        this.scroll_loading = true
        this.filter_search = ''
        await this.getBADT()
      }
    },
    hideModalAssignPerson () {
      window.$('#modal__assign_person').modal('hide')
      this.resetData()
    },
    resetData () {
      this.form.id = ''
      this.form.fromDate = moment().format('YYYY-MM-DD HH:mm')
      this.form.toDate = moment().add(4, 'hours').format('YYYY-MM-DD HH:mm')
      this.form.room_id = ''
      this.form.status = ''
      this.selectedMedicalRecordId = ''
      this.selectedMedicalRecord = {}
      this.isService = false
      this.form.price = ''
      this.$emit('clearData')
    },
    confirmReservation () {
      const self = this
      if (!self.isService) {
        if (
          !self.selectedMedicalRecordId ||
          !self.form.fromDate ||
          !self.form.toDate
        ) {
          self.$message({
            message: self.$t('Vui lòng điền đầy đủ thông tin'),
            type: 'error'
          })
          return
        }
      } else {
        if (!self.form.fromDate || !self.form.toDate) {
          self.$message({
            message: self.$t('Vui lòng điền đầy đủ thông tin'),
            type: 'error'
          })
          return
        }
      }
      if (!this.update) {
        this.createReservation()
      } else {
        this.updateReservation()
      }
    },
    async updateReservation () {
      let self = this
      const params = {
        bed_id: this.dataBed.id,
        date_in: this.moment(this.form.fromDate).format('YYYY-MM-DD HH:mm'),
        date_out: this.moment(this.form.toDate).format('YYYY-MM-DD HH:mm'),
        emr_id: this.isService ? null : this.selectedMedicalRecord.id,
        patient_id: this.isService
          ? null
          : this.selectedMedicalRecord.person_id,
        room_id: this.dataRoom.value,
        status: this.isService ? 2 : 1,
        workspace_id: this.$globalClinic.id
      }

      this.loading = true
      let r = await self.$rf
        .getRequest('DoctorRequest')
        .updateReservations(params, this.form.id)
        .then((r) => {
          self.$emit('refresh')
          self.hideModalAssignPerson()
          return r
        })
        .catch((e) => {
          console.log(e)
          self.$toast.open({
            message: 'Giường đã được sử sụng trong khoảng thời gian đã chọn!',
            type: 'warning'
          })
          return false
        })
      this.loading = false

      return r
    },
    async createReservation () {
      let self = this
      const params = {
        bed_id: this.dataBed.id,
        date_in: this.moment(this.form.fromDate).format('YYYY-MM-DD HH:mm'),
        date_out: this.moment(this.form.toDate).format('YYYY-MM-DD HH:mm'),
        emr_id: this.selectedMedicalRecord.id,
        patient_id: this.selectedMedicalRecord.person_id,
        room_id: this.dataRoom.value,
        status: this.isService ? 2 : 1,
        workspace_id: this.$globalClinic.id
      }

      this.loading = true
      let r = await self.$rf
        .getRequest('DoctorRequest')
        .createReservation(params)
        .then((r) => {
          self.$emit('refresh')
          self.hideModalAssignPerson()
          return r
        })
        .catch((e) => {
          console.log(e)
          self.$toast.open({
            message: 'Giường đã được sử dụng trong khoảng thời gian đã chọn!',
            type: 'warning'
          })
          return false
        })
      this.loading = false

      return r
    },
    getAge (person) {
      return (
        person?.birthday &&
        (window.moment().diff(person.birthday, 'years', false)
          ? window.moment().diff(person.birthday, 'years', false)
          : '')
      )
    },
    getLabel (item) {
      const age = this.getAge(item.person)
        ? `(${this.getAge(item.person)} tuổi)`
        : ''
      const ref_id =
        item.ref_id || item?.person?.doc_no
          ? `- ${item.ref_id || item?.person?.doc_no}`
          : ''
      return item.person?.name ? `${item.person.name} ${age} ${ref_id}` : ''
    }
  }
}
</script>
<style lang="scss" scoped>
// ::v-deep {
//   .el-input-group__append{
//   border: none;
//   }
// }
.select-box {
  padding-top: 5px;
  padding-bottom: 5px;
  background-color: #f4f4f4;
  border-radius: 5px;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
</style>
