import { render, staticRenderFns } from "./BedList.vue?vue&type=template&id=1cb0cf9a&scoped=true"
import script from "./BedList.vue?vue&type=script&lang=js"
export * from "./BedList.vue?vue&type=script&lang=js"
import style0 from "./BedList.vue?vue&type=style&index=0&id=1cb0cf9a&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1cb0cf9a",
  null
  
)

export default component.exports