<template>
  <div>
    <div class="modal fade" id="modal__add_bed">
      <div class="modal-dialog modal-md modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">{{ update ? "Cập nhật thông tin giường" : "Thêm mới giường" }}</h5>
          </div>
          <div v-loading="loading" class="modal-body txt-grey-900">
            <div class="flex">
              <div style="color: #20419B;" class="font-bold">{{ $globalClinic.name || '' }}</div>
              <!-- <div class="ml-auto flex" v-if="update">
              <label class="fs-14 fw-500">Bảo trì</label>
                <el-switch
                  v-model="isService"
                  class="ml-2"
                  size="large"
                />
              </div>-->
            </div>
            <div
              v-if="update"
              style="color: #20419B;"
              class="font-bold mt-2"
            >{{ dataRoom.name }} - {{ dataRoom.address }}</div>

            <div class="row" v-if="!update">
              <div class="col-md-12 mt-6">
                <label class="fs-14 fw-500 required">Phòng</label>
                <el-select
                  v-model="roomId"
                  filterable
                  @change="handleSelectRoom"
                  class="w-full"
                  placeholder="Chọn phòng"
                  :loading="loading"
                >
                  <el-option
                    v-for="item in roomGroups"
                    :key="item.value + 'room'"
                    :label="item.name + ' - ' + item.address"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12 mt-6">
                <label class="fs-14 fw-500 required">Loại giường</label>
                <input
                  v-model="form.type_bed"
                  type="text"
                  class="form--input fs-16 fw-500"
                  placeholder="Nhập loại giường"
                />
              </div>
            </div>

            <div class="mt-6 row">
              <div class="col-md-12">
                <label class="fs-14 fw-500 required">Tên giường</label>
                <input v-model="form.name" class="form--input fw-500" placeholder="Nhập tên giường" />
              </div>
            </div>
            <div class="mt-6 row">
              <div class="col-md-12">
                <label
                  class="fs-14 fw-500 required"
                >Giá bảo hiểm y tế (VNĐ)</label>
                <cleave
                  v-model="form.insurance_price"
                  class="form--input"
                  name="card"
                  :raw="false"
                  :options="cleaveOptions.number"
                  placeholder="10,000"
                />
              </div>
            </div>
            <div class="mt-6 row">
              <div class="col-md-12">
                <label
                  class="fs-14 fw-500 required"
                >Giá dịch vụ theo yêu cầu (VNĐ)</label>
                <cleave
                  v-model="form.price"
                  class="form--input"
                  name="card"
                  :raw="false"
                  :options="cleaveOptions.number"
                  placeholder="10,000"
                />
              </div>
            </div>
            <div class="mt-6 row">
              <div class="col-md-12">
                <label
                  class="fs-14 fw-500 required"
                >Mô tả</label>
                <input v-model="form.description" class="form--input fw-500" placeholder="Nhập mô tả giường" />
              </div>
            </div>
            <div class="mt-6 text-sm font-medium flex">
              <button
                @click="deleteBed"
                v-if="update"
                class="flex items-center justify-center border-0 focus:outline-none mx-3 bg-red-700 text-white rounded-md h-9 px-3 py-2"
              >
                <div>Xóa</div>
              </button>
              <div class="ml-auto flex">
                <button
                  @click="confirmBed"
                  class="flex items-center justify-center border-0 focus:outline-none mx-3 bg-blue-700 text-white rounded-md h-9 px-3 py-2"
                >
                  <div v-if="!update">Thêm</div>
                  <div v-else>Cập nhật</div>
                </button>
                <button
                  data-dismiss="modal"
                  @click="showModalAddBed(false)"
                  class="flex items-center justify-center border-0 focus:outline-none bg-blue-50 text-primary rounded-md h-9 px-3 py-2"
                >
                  <div>Hủy</div>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { ButtonHoDo } from '@/components/Button'
import Cleave from 'vue-cleave-component'
import vSelect from 'vue-select'

export default {
  name: 'ModalAddBed',
  components: { ButtonHoDo, Cleave, vSelect },
  props: ['update', 'dataRoom', 'dataBed', 'roomGroups'],
  data () {
    return {
      roomId: null,
      isService: false,
      loading: false,
      form: {
        id: null,
        name: '',
        type_bed: '',
        status: '',
        price: '',
        description: '',
        insurance_price: ''
      },
      cleaveOptions: {
        number: {
          numeral: true,
          numeralThousandsGroupStyle: 'thousand'
        }
      }
    }
  },
  watch: {
    dataBed () {
      this.handeDataBed()
    }
  },

  mounted () {
    window.$(document).ready(function () {
      window.$('#modal__add_benhan').on('hidden.bs.modal', function (e) {
        // self.error = false
      })
    })
    this.handeDataBed()
  },
  methods: {
    handleSelectRoom () {
      const selectedRoom = this.roomGroups.find(x => x.value === this.roomId)
      this.form.price = selectedRoom.price
      this.form.insurance_price = selectedRoom.insurance_price || ''
    },
    handeDataBed () {
      this.form.price = this.dataRoom.price
      this.form.insurance_price = this.dataRoom.insurance_price || ''
      if (this.dataBed) {
        let item = this.dataBed
        this.form.name = item.name
        this.form.type_bed = item.type_bed
        this.form.price = item.price
        this.form.insurance_price = item.insurance_price
        this.form.description = item.description
        this.form.id = item.id
        if (!this.update) {
          this.roomId = this.dataRoom.value
          const indexAll = this.roomGroups.findIndex(x => x.value === -1)
          if (indexAll > -1) {
            this.roomGroups.splice(indexAll, 1)
          }
        }
      }
    },

    showModalAddBed (show) {
      window.$('#modal__add_bed').modal(show ? 'show' : 'hide')

      if (!show) {
        this.form.name = ''
        this.form.type_bed = ''
        this.form.price = ''
        this.form.insurance_price = ''
        this.form.description = ''
        this.form.id = null
        this.roomId = null
      }
      this.$emit('clearData')
    },
    confirmBed () {
      const self = this
      console.log(self.form)
      if (
        !self.form.name ||
        !self.form.name.length ||
        !self.form.type_bed ||
        !self.form.type_bed.length ||
        !self.form.description ||
        !self.form.description.length ||
        !self.form.insurance_price ||
        !self.form.insurance_price.length ||
        !self.form.price ||
        !self.form.price.length
      ) {
        alert('Vui lòng điền đầy đủ thông tin')
        return
      }
      if (!this.update) {
        this.createBed()
      } else {
        this.updateBed()
      }
    },
    deleteBed () {
      this.$confirm(
        `Bạn có chắc chắn muốn xoá ${this.dataBed.name} không?`,
        this.$t('Cảnh báo')
      )
        .then(_ => {
          this.loading = true
          this.$rf
            .getRequest('DoctorRequest')
            .deleteBed(this.form.id)
            .then(r => {
              this.$emit('refresh')
              this.showModalAddBed(false)
            })
            .catch(e => {
              console.log(e)
              this.$toast.open({
                message: 'Giường đang được sử dụng!',
                type: 'warning'
              })
              return false
            })
          this.loading = false
        })
        .catch(_ => {})
    },
    async createBed () {
      let self = this
      const params = {
        name: this.form.name,
        type_bed: this.form.type_bed,
        price: parseFloat(
          Number(this.form?.price.toString()?.replaceAll(',', '')) || 0
        ),
        room_id: this.roomId,
        workspace_id: this.$globalClinic.id,
        insurance_price: parseFloat(
          Number(this.form?.insurance_price.toString()?.replaceAll(',', '')) || 0
        ),
        description: this.form.description
      }

      this.loading = true
      let r = await self.$rf
        .getRequest('DoctorRequest')
        .createBedService(params)
        .then(r => {
          self.$emit('refresh')
          self.showModalAddBed(false)
          return r
        })
        .catch(e => {
          console.log(e)
          self.$toast.open({
            message: 'Có lỗi trong quá trình tạo',
            type: 'warning'
          })
          return false
        })
      this.loading = false

      return r
    },
    async updateBed () {
      let self = this
      const params = {
        name: this.form.name,
        type_bed: this.form.type_bed,
        price: parseFloat(
          Number(this.form?.price.toString()?.replaceAll(',', '')) || 0
        ),
        room_id: this.dataRoom.id,
        workspace_id: this.$globalClinic.id,
        insurance_price: parseFloat(
          Number(this.form?.insurance_price.toString()?.replaceAll(',', '')) || 0
        ),
        description: this.form.description
      }

      this.loading = true
      let r = await self.$rf
        .getRequest('DoctorRequest')
        .updateBed(params, this.form.id)
        .then(r => {
          self.$emit('refresh')
          self.showModalAddBed(false)
          return r
        })
        .catch(e => {
          console.log(e)
          self.$toast.open({
            message: 'Có lỗi trong quá trình tạo',
            type: 'warning'
          })
          return false
        })
      this.loading = false

      return r
    }
  }
}
</script>
<style lang="scss" scoped>
// ::v-deep {
//   .el-input-group__append{
//   border: none;
//   }
// }
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
</style>
