<template>
  <div class="w-full container flex flex-col justify-between relative" v-loading="loading">
    <router-link to="/room-management">
      <div class="flex gap-2 mt-4">
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M8.5002 12.7998L14.2002 18.3998C14.6002 18.7998 15.2002 18.7998 15.6002 18.3998C16.0002 17.9998 16.0002 17.3998 15.6002 16.9998L10.7002 11.9998L15.6002 6.9998C16.0002 6.5998 16.0002 5.9998 15.6002 5.5998C15.4002 5.3998 15.2002 5.2998 14.9002 5.2998C14.6002 5.2998 14.4002 5.3998 14.2002 5.5998L8.5002 11.1998C8.1002 11.6998 8.1002 12.2998 8.5002 12.7998C8.5002 12.6998 8.5002 12.6998 8.5002 12.7998Z"
            fill="black"
          />
        </svg>

        <span class="text-base font-bold mt-1 leading-none text-black">{{ $t('Danh sách Phòng') }}</span>
      </div>
    </router-link>
    <div class="relative flex items-center justify-center mt-4 mb-3">
      <div class="flex items-center justify-center">
        <span
          class="txt-grey-900 text-3xl font-bold mt-1 leading-none text-pris uppercase"
          style="color: #20409B;"
        >{{ $t('Danh sách giường') }}</span>
      </div>
    </div>
    <div class="flex flex-col md:flex-row items-center -mx-3 gap-2">
      <div class="px-3 lg:w-3/5 w-full">
        <v-select
          placeholder="Tất cả các phòng"
          class="border-2 border-solid border-blue-400 rounded-lg"
          v-model="roomSelect"
          label="name"
          :clearable="false"
          :options="rooms"
          :disabled="isLoading"
          @input="handleAddRoom"
          @search="getListRoom"
          multiple
        ></v-select>
      </div>
      <div class="px-3 lg:w-2/5 w-full flex flex-col md:flex-row gap-2 justify-content-lg-end">
        <button
          @click="handleShowListPatientInRoom(true)"
          type="button"
          class="flex items-center justify-center border-0 focus:outline-none bg-blue-700 hover:bg-blue-800 text-white transition-all duration-200 rounded-lg h-9 px-3 py-2"
        >
          <i class="el-icon-folder-opened text-lg mr-2" />
          <span>{{ $t('Danh sách Bệnh nhân') }}</span>
        </button>
        <button
          @click="handleCreateBed(true)"
          type="button"
          class="flex items-center justify-center border-0 focus:outline-none bg-blue-700 hover:bg-blue-800 text-white transition-all duration-200 rounded-lg h-9 px-3 py-2"
        >
          <i class="el-icon-plus text-lg mr-2" />
          <span>{{ $t('Thêm giường') }}</span>
        </button>
      </div>
    </div>

    <div class="py-3">
      <BedFilters @setFilters="handleFilters" :default-filters="defaultFilters" />
    </div>
    <div class="overflow-x-scroll">
      <div class="cs-chart-container">
        <div v-for="(roomItem, index) in roomGroups" :key="`room_${roomItem.ID}`" class="mt-1">
          <div class="row">
            <div
              v-if="roomGroups.length > 1"
              style="background-color: #20409B;"
              class="col-md-1 rounded-lg text-center"
            >
              <div class="flex flex-col h-full justify-center">
                <div class="text-white">{{ roomItem.name }}</div>
              </div>
            </div>
            <div :class="roomGroups.length > 1 ? 'col-md-11' :''">
              <GGanttChart
                :chart-start="chartStart"
                :chart-end="chartEnd"
                :grid="grid"
                :hide-timeaxis="index !== 0"
                :push-on-overlap="true"
                snap-back-on-overlap
                :highlighted-hours="highlightedHours"
                :row-label-width="`${rowLabelWidth}%`"
                :row-height="rowHeight"
                :theme="selectedTheme"
              >
                <div v-for="row in roomItem.listBeds" :key="`bed_${row.id}`">
                  <GGanttRow
                    class="cursor-pointer"
                    @assign-person="handleAssignPerson"
                    @update-assign-bed="handldUpdateAssignBed"
                    @edit-bed="handleUpdateBed"
                    :label="row.label"
                    :price="row.price"
                    :type_bed="row.type_bed"
                    :insurance_price="row.insurance_price"
                    :description="row.description"
                    :id="row.id"
                    :roomId="row.roomId"
                    :bars="row.barList"
                    :highlight-on-hover="highlightOnHover"
                    bar-start="myStart"
                    bar-end="myEnd"
                  >
                    <template #bar-label="{bar}">
                      <span>{{bar.label}}</span>
                    </template>
                  </GGanttRow>
                </div>
              </GGanttChart>
            </div>
          </div>
        </div>
      </div>
    </div>

    <ModalAddBed
      @refresh="onRefresh"
      :update="isUpdateBed"
      :data-room="roomData"
      :data-bed="currentDataBed"
      :room-groups="rooms"
      @clearData="currentDataBed = {}"
    />
    <ModalAssignPerson
      v-if="roomGroups.length"
      @refresh="onRefresh"
      :update="isUpdateAssignBed"
      :data-room="roomData"
      :data-bed="currentDataBed"
      :data-reserve="currentDataReserve"
      @clearData="currentDataReserve = {}"
    />
    <ModalListPatientInRoom
      @refresh="onRefresh"
      :room-groups="roomGroups"
      :actionKey="actionKey"
      :dateFilters="filterOptions"
    />
  </div>
</template>
<script>
import { AppPagination } from '@/components/Common'
import Pagination from '@/components/Pagination'
import BedFilters from '@/components/RoomServiceFilters/BedFilters'
import { ROOM_STATUS, RESERVATION_STATUS } from '@/utils/constants'
import ModalAddBed from '@/pages/RoomService/Modal/ModalAddBed.vue'
import ModalListPatientInRoom from '@/pages/RoomService/Modal/ModalListPatientInRoom.vue'
import ModalAssignPerson from '@/pages/RoomService/Modal/ModalAssignPerson.vue'
import GGanttRow from '@/components/GGantChart/GGanttRow.vue'
import GGanttChart from '@/components/GGantChart/GGanttChart.vue'
import appUtils from '@/utils/appUtils'
import moment from 'moment'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'
export default {
  components: {
    Pagination,
    BedFilters,
    AppPagination,
    ModalAddBed,
    ModalListPatientInRoom,
    ModalAssignPerson,
    GGanttChart,
    GGanttRow,
    vSelect
  },
  name: 'ListBed',
  computed: {},
  data () {
    return {
      bedIds: [],
      defaultFilters: {},
      currentDataReserve: {},
      currentDataBed: {},
      roomData: {},
      loading: false,
      isUpdateBed: false,
      currentDataRoom: null,
      isUpdateAssignBed: false,
      ROOM_STATUS,
      RESERVATION_STATUS,
      search: null,
      filterOptions: {
        fromDate: this.moment().format('YYYY-MM-DD'),
        toDate: this.moment()
          .add(7, 'days')
          .format('YYYY-MM-DD'),
        keyword: ''
      },
      chartStart: '2020-03-02 00:00',
      chartEnd: '2020-03-09 00:00',
      pushOnOverlap: true,
      grid: true,
      rowHeight: 50,
      rowLabelWidth: 10,
      hideTimeaxis: false,
      highlightOnHover: true,
      hours: [...Array(24).keys()],
      highlightedHours: [12, 0],
      showContextmenu: false,
      contextmenuTimeout: null,
      contextmenuX: 0,
      contextmenuY: 0,
      selectedTheme: 'default',
      themes: [
        'default',
        'vue',
        'dark',
        'material-blue',
        'creamy',
        'slumber',
        'sky',
        'crimson',
        'grove',
        'fuchsia',
        'flare'
      ],
      listBeds: [],
      roomSelect: [],
      rooms: [],
      isLoading: false,
      roomGroups: [],
      actionKey: 0
    }
  },
  async created () {
    const queryParams = this.$route.query
    this.filterOptions = {
      fromDate: queryParams.fromDate,
      toDate: queryParams.toDate
    }
    this.defaultFilters = {
      fromDate: queryParams.fromDate,
      toDate: queryParams.toDate
    }
    this.chartStart = queryParams.fromDate
    this.chartEnd = queryParams.toDate
    const firstCallParam = {
      search: null,
      isFirstCall: true
    }
    await this.getListRoom(firstCallParam.search, firstCallParam.isFirstCall)
    // this.getDetailRoom()
  },
  methods: {
    handleFilters (data) {
      this.filterOptions = {
        fromDate: data.fromDate,
        toDate: data.toDate
      }
      this.chartStart = data.fromDate
      this.chartEnd = data.toDate
      // this.getDetailRoom()
      this.getListRoom()
      this.forceRerender()
    },
    handldUpdateAssignBed (data) {
      console.log(data)
      this.isUpdateAssignBed = true
      this.currentDataBed = data.bedObject
      this.roomData = this.rooms.find(
        x => x.value === this.currentDataBed.roomId
      )
      this.currentDataReserve = data.dataReserve
      console.log(this.currentDataReserve)
      window.$('#modal__assign_person').modal(data.show ? 'show' : 'hide')
    },
    handleAssignPerson (data) {
      this.currentDataBed = data.bedObject
      this.roomData = this.rooms.find(
        x => x.value === this.currentDataBed.roomId
      )
      this.currentDataReserve = {
        fromDate: moment().format('YYYY-MM-DD HH:mm'),
        toDate: moment()
          .add(4, 'hours')
          .format('YYYY-MM-DD HH:mm')
      }
      this.isUpdateAssignBed = false
      window.$('#modal__assign_person').modal(data.show ? 'show' : 'hide')
    },
    handleDetailListBedInRoom (room) {
      this.$router.push({ path: `/doctor/room-service/list-bed/${room.id}` })
    },
    onShowModalAdd (show) {
      window.$('#modal__add_room').modal(show ? 'show' : 'hide')
    },
    handleCreateBed (show) {
      if (this.roomSelect.length === 1) {
        this.roomData = this.roomSelect[0]
        this.currentDataBed = {
          price: this.roomData?.price,
          insurance_price: this.roomData?.insurance_price
        }
      } else {
        this.currentDataBed = {}
      }
      this.isUpdateBed = false
      window.$('#modal__add_bed').modal(show ? 'show' : 'hide')
    },
    handleUpdateBed (data) {
      this.currentDataBed = data.bedObject
      this.roomData = this.rooms.find(
        x => x.value === this.currentDataBed.roomId
      )
      this.isUpdateBed = true
      window.$('#modal__add_bed').modal(data.show ? 'show' : 'hide')
    },
    handleShowListPatientInRoom (show) {
      console.log(this.roomGroups)
      this.forceRerender()
      window
        .$('#modal__show_list_patient_in_room')
        .modal(show ? 'show' : 'hide')
    },
    getRoomStatus (stt) {
      switch (stt) {
        case ROOM_STATUS.CODE.OCCUPIED:
          return {
            class: 'border border-primary rounded-full',
            text: 'Hết giường'
          }
        case ROOM_STATUS.CODE.ON_SERVICE:
          return {
            class: 'border border-primary rounded-full',
            text: 'Bảo trì'
          }
        default:
          return {
            class: '',
            text: ''
          }
      }
    },
    setBackGround (roomStatus) {
      switch (roomStatus) {
        case ROOM_STATUS.CODE.AVAILABLE:
          return ''
        case ROOM_STATUS.CODE.OCCUPIED:
          return 'bg-red-300'
        case ROOM_STATUS.CODE.ON_SERVICE:
          return 'bg-yellow-200'
        default:
          return ''
      }
    },
    formatDMY (row) {
      return row.birthday && window.moment(row.birthday).format('DD-MM-YYYY')
    },
    addHr (obj) {
      this.$refs.ModalAddHR.openDialog(obj, 'create')
    },
    goToViewDetail (row) {
      const id = row.id
      self.$router.push({
        path: `/doctor/contact-detail/${id}?type=person-info`
      })
    },
    onRefresh () {
      // this.getDetailRoom()
      this.getListRoom()
    },
    async getDetailRoom () {
      this.loading = true
      const roomId = this.$route.params.id
      const params = {
        // from: this.moment(this.filterOptions.fromDate).utc().format(),
        // to: this.moment(this.filterOptions.toDate).utc().format()
      }
      await this.$rf
        .getRequest('DoctorRequest')
        .getDetailRooms(roomId, this.$globalClinic.id, params)
        .then(res => {
          this.roomData = res.data.data
          if (this.roomData.reservation_data.length > 0) {
            this.listBeds = this.roomData.reservation_data.map(x => ({
              label: x.beds.name,
              price: x.beds.price.toString(),
              type_bed: x.beds.type_bed,
              insurance_price: x.beds.insurance_price.toString(),
              description: x.beds.description,
              id: x.beds.ID,
              barList: []
            }))
            this.listBeds.sort((a, b) => (a.id > b.id ? 1 : -1))
            this.getReservations()
          } else {
            this.listBeds = []
          }
          this.loading = false
        })
        .catch(err => {
          console.log(err)
          // this.$message({
          //   message: this.$t(
          //     'Lỗi lấy danh sách phòng'
          //   ),
          //   type: 'error'
          // })
          this.loading = false
        })
    },
    async getListRoomWithListBed () {
      this.loading = true
      const params = {
        room_ids:
          this.roomSelect.length > 0
            ? this.roomSelect.map(x => x.value).join(',')
            : null,
        ws_id: this.$globalClinic.id,
        page_num: 1,
        page_size: 10000
      }
      await this.$rf
        .getRequest('DoctorRequest')
        .getListRoomServiceWithBeds(params)
        .then(async res => {
          this.roomGroups = res.data.data
          this.roomGroups.forEach((x, index) => {
            if (this.roomGroups[index].beds.length > 0) {
              this.bedIds.push(...this.roomGroups[index].beds.map(x => x.ID))
              this.roomGroups[index].listBeds = this.roomGroups[index].beds.map(
                x => ({
                  label: x.name,
                  price: x.price.toString(),
                  description: x.description,
                  insurance_price: x.insurance_price.toString(),
                  roomId: x.room_id,
                  type_bed: x.type_bed,
                  id: x.ID,
                  barList: []
                })
              )
            } else {
              this.roomGroups[index].listBeds = []
            }
          })
          this.roomGroups.reverse()
          await this.getReservationsByBedIds()
          this.loading = false
        })
        .catch(err => {
          console.log(err)
          this.loading = false
        })
    },
    async getReservationsByBedIds () {
      if (this.bedIds.length > 0) {
        this.loading = true
        const listBedString = this.bedIds.join(',')
        const params = {
          from: this.filterOptions.fromDate,
          to: this.filterOptions.toDate,
          bed_ids: listBedString
        }
        await this.$rf
          .getRequest('DoctorRequest')
          .getReservations(params)
          .then(res => {
            const resp = res.data.data
            // this.roomGroups.forEach((x, index) => {
            //   this.roomGroups[index].barList = []
            // })
            resp.forEach(i => {
              this.roomGroups.forEach((x, index) => {
                // eslint-disable-next-line standard/computed-property-even-spacing
                const foundReservedIndex = this.roomGroups[
                  index
                ].listBeds.findIndex(o => o.id === i.bed_id)
                const isFoundReservation = foundReservedIndex !== -1
                const isReservationNotCancelled =
                  i.status !== RESERVATION_STATUS.CODE.CANCELLED
                if (isFoundReservation && isReservationNotCancelled) {
                  // eslint-disable-next-line standard/computed-property-even-spacing
                  this.roomGroups[index].listBeds[
                    foundReservedIndex
                  ].barList.push({
                    id: i.ID,
                    myStart: this.moment(i.date_in).format('YYYY-MM-DD HH:mm'),
                    myEnd: this.moment(i.date_out_actual || i.date_out).format('YYYY-MM-DD HH:mm'),
                    label: i.name,
                    status: i.status,
                    medical_record_id: i.emr_id,
                    patient_id: i.patient_id,
                    ganttBarConfig: {
                      color: 'white',
                      backgroundColor: appUtils.getRandomColor(),
                      immobile: true,
                      borderRadius: 0
                    }
                  })

                  // Incase reservation is under maintenance

                  //   // eslint-disable-next-line standard/computed-property-even-spacing
                  //   this.roomGroups[index].listBeds[
                  //     foundReservedIndex
                  //   ].barList.push({
                  //     id: i.ID,
                  //     myStart: this.moment(i.date_in).format(
                  //       'YYYY-MM-DD HH:mm'
                  //     ),
                  //     myEnd: this.moment(i.date_out).format('YYYY-MM-DD HH:mm'),
                  //     label: 'Bảo trì',
                  //     status: i.status,
                  //     medical_record_id: null,
                  //     ganttBarConfig: {
                  //       color: 'black',
                  //       background:
                  //         'repeating-linear-gradient(45deg,#c9c9c9,#c9c9c9 10px,#ef4444 10px,#ef4444 20px)',
                  //       immobile: true,
                  //       borderRadius: 0
                  //     },
                  //     immobile: true,
                  //     borderRadius: 0
                  //   })
                }
              })
            })

            this.chartStart = this.moment(this.filterOptions.fromDate).format(
              'YYYY-MM-DD HH:mm'
            )
            this.chartEnd = this.moment(this.filterOptions.toDate)
              .add(1, 'days')
              .format('YYYY-MM-DD HH:mm')
            this.loading = false
          })
          .catch(err => {
            console.log(err)
            this.$message({
              message: this.$t('Lỗi lấy danh sách đặt phòng'),
              type: 'error'
            })
            this.loading = false
          })
      }
    },
    async getReservations () {
      this.loading = true
      const listBedString = this.listBeds.map(x => x.id).join(',')
      const params = {
        from: this.filterOptions.fromDate,
        to: this.filterOptions.toDate,
        bed_ids: listBedString
      }
      await this.$rf
        .getRequest('DoctorRequest')
        .getReservations(params)
        .then(res => {
          const resp = res.data.data
          this.listBeds.forEach(x => {
            x.barList = []
          })
          resp.forEach(i => {
            const foundReservedIndex = this.listBeds.findIndex(
              o => o.id === i.bed_id
            )
            if (foundReservedIndex !== -1) {
              if (i.status === 1) {
                this.listBeds[foundReservedIndex].barList.push({
                  id: i.ID,
                  myStart: this.moment(i.date_in).format('YYYY-MM-DD HH:mm'),
                  myEnd: this.moment(i.date_out).format('YYYY-MM-DD HH:mm'),
                  label: i.name,
                  status: i.status,
                  medical_record_id: i.emr_id,
                  ganttBarConfig: {
                    color: 'white',
                    backgroundColor: appUtils.getRandomColor(),
                    immobile: true,
                    borderRadius: 0
                  }
                })
              } else {
                this.listBeds[foundReservedIndex].barList.push({
                  id: i.ID,
                  myStart: this.moment(i.date_in).format('YYYY-MM-DD HH:mm'),
                  myEnd: this.moment(i.date_out).format('YYYY-MM-DD HH:mm'),
                  label: 'Bảo trì',
                  status: i.status,
                  medical_record_id: null,
                  ganttBarConfig: {
                    color: 'black',
                    background:
                      'repeating-linear-gradient(45deg,#c9c9c9,#c9c9c9 10px,#ef4444 10px,#ef4444 20px)',
                    immobile: true,
                    borderRadius: 0
                  },
                  immobile: true,
                  borderRadius: 0
                })
              }
            }
          })
          this.chartStart = this.moment(this.filterOptions.fromDate).format(
            'YYYY-MM-DD HH:mm'
          )
          this.chartEnd = this.moment(this.filterOptions.toDate)
            .add(1, 'days')
            .format('YYYY-MM-DD HH:mm')
          this.loading = false
        })
        .catch(err => {
          console.log(err)
          this.$message({
            message: this.$t('Lỗi lấy danh sách đặt phòng'),
            type: 'error'
          })
          this.loading = false
        })
    },
    handleAddRoom () {
      if (this.roomSelect.some(x => x.value === -1)) {
        this.roomSelect = []
      }
      this.getListRoomWithListBed()
    },
    async getListRoom (search, isFirstCall = false) {
      const params = {
        page_num: 1,
        page_size: 10000,
        from: this.filterOptions.fromDate,
        to: this.filterOptions.toDate,
        keyword: search,
        workspaceID: this.$globalClinic.id
      }
      this.loading = true
      await this.$rf
        .getRequest('DoctorRequest')
        .getListRoomService(params)
        .then(res => {
          const resp = res.data
          this.rooms = resp.rooms.map(x => ({
            name: x.name,
            value: x.ID,
            address: x.address,
            price: x.price
          }))
          this.rooms = [{ value: -1, name: 'Tất cả phòng' }, ...this.rooms]
          if (isFirstCall && this.roomSelect.length === 0) {
            const foundedRoom = this.rooms.find(
              x => x.value === parseInt(this.$route.params.id)
            )
            this.roomSelect.push({
              name: foundedRoom?.name,
              value: parseInt(this.$route.params.id),
              price: foundedRoom?.price,
              address: foundedRoom?.address
            })
          }
          this.getListRoomWithListBed()
          this.loading = false
        })
        .catch(err => {
          console.log(err)
          this.loading = false
        })
    },
    forceRerender () {
      this.actionKey += 1
    }
  }
}
</script>
<style lang="scss" scoped>
// @import "vue-select/dist/vue-select.css";
.radius-10 {
  border-radius: 10px;
}

.room-item {
  display: flex;
  flex-direction: column;
  min-height: 10rem;
}
/* hr { */
/* height: 3px;
    width: 100%;
    border: 0;
    border-top: 2px solid #20419B;
    border-width: 10px;
    background-color: #20419B; */
/* border-top: 3px solid #20419B; */
/* } */
.vDivider {
  width: 100%;
  height: 1px;
  margin: 10px auto;
  background: #20419b;
  border-bottom: 1px solid #20419b;
}
.radius-20 {
  border-radius: 10px;
  border-color: #20419b;
}

.cs-chart-container {
  width: 100%;
  min-width: 1500px;
  // overflow-x: scroll;
}
::v-deep {
  .vs__selected {
    display: flex;
    background-color: #c7d3f3;
    color: #20419b;
    // justify-content: center;
    width: 10rem;
  }
  .vs__deselect {
    margin-left: auto;
    margin-right: 0.2rem;
  }
  .vs__search::placeholder {
    font-size: 1rem;
    color: #20419b;
    opacity: 1;
    text-align: center;
  }
}
</style>
