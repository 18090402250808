<template>
  <div>
    <div class="modal fade" id="modal__add_room">
      <div class="modal-dialog modal-md modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">
              {{ update ? "Cập nhật thông tin" : "Thêm mới phòng" }}
            </h5>
          </div>
          <div v-loading="loading" class="modal-body txt-grey-900">
            <div class="flex">
              <div style="color: #20419b" class="font-bold">
                {{ $globalClinic.name || "" }}
              </div>
              <div class="ml-auto flex" v-if="update">
                <label class="fs-14 fw-500">Bảo trì</label>
                <el-switch v-model="isService" class="ml-2" size="large" />
              </div>
            </div>

            <div class="row">
              <div class="col-md-12 mt-6">
                <label class="fs-14 fw-500 required">Tên phòng</label>
                <input
                  v-model="form.name"
                  type="text"
                  class="form--input fs-16 fw-500"
                  placeholder="Nhập tên Phòng"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-md-12 mt-6 flex flex-col">
                <label class="fs-14 fw-500 required">Chọn tầng</label>
                <el-select
                  v-model="form.loc_id"
                  filterable
                  allow-create
                  clearable
                  default-first-option
                  placeholder="Chọn tầng"
                >
                  <el-option
                    v-for="item in listFloors"
                    :key="item.id"
                    :label="item.Name"
                    :value="item.id"
                  ></el-option>
                </el-select>
              </div>
            </div>

            <div class="mt-6 row">
              <div class="col-md-12">
                <label class="fs-14 fw-500 required">Địa chỉ</label>
                <input
                  v-model="form.address"
                  type="text"
                  class="form--input fw-500"
                  placeholder="Nhập địa chỉ"
                />
              </div>
            </div>
            <div class="mt-6 row">
              <div class="col-md-12">
                <label class="fs-14 fw-500 required"
                  >Giá (áp dụng theo ngày đối với 1 người/1 giường) (VNĐ)</label
                >
                <!-- <input 
                  v-model="form.price" 
                  type="number" 
                  class="form--input fw-500" 
                placeholder="Nhập giá" />-->
                <cleave
                  v-model="form.price"
                  class="form--input"
                  name="card"
                  :raw="false"
                  :options="cleaveOptions.number"
                  placeholder="10,000"
                />
              </div>
            </div>
            <div class="mt-6 text-sm font-medium flex">
              <button
                @click="deleteRoom"
                v-if="update"
                class="flex items-center justify-center border-0 focus:outline-none mx-3 bg-red-700 text-white rounded-md h-9 px-3 py-2"
              >
                <div>Xóa</div>
              </button>
              <div class="flex ml-auto">
                <button
                  @click="confirmRoom"
                  class="flex items-center justify-center border-0 focus:outline-none mx-3 bg-blue-700 text-white rounded-md h-9 px-3 py-2"
                >
                  <div v-if="!update">Tạo mới</div>
                  <div v-else>Cập nhật</div>
                </button>
                <button
                  data-dismiss="modal"
                  @click="showModalAddRoom(false)"
                  class="flex items-center justify-center border-0 focus:outline-none bg-blue-50 text-primary rounded-md h-9 px-3 py-2"
                >
                  <div>Hủy</div>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { ButtonHoDo } from '@/components/Button'
import { ROOM_STATUS } from '../../../utils/constants'
import Cleave from 'vue-cleave-component'

export default {
  name: 'ModalAddRoom',
  components: { ButtonHoDo, Cleave },
  props: ['update', 'dataRoom'],
  data () {
    return {
      isService: false,
      loading: false,
      form: {
        name: '',
        address: '',
        status: '',
        price: '',
        loc_id: ''
      },
      form_update: {
        name: '',
        address: '',
        status: null
      },
      cleaveOptions: {
        number: {
          numeral: true,
          numeralThousandsGroupStyle: 'thousand'
        }
      },
      listFloors: []
    }
  },
  watch: {
    dataRoom () {
      this.handeDataRoom()
    },
    'form.loc_id': {
      async handler (value) {
        if (typeof value !== 'number') {
          this.newFloor = value
          if (!value) return
          await this.createFloor(this.newFloor)
          await this.fetchListFloors()
          this.form.loc_id = this.listFloors.find(
            (elm) => elm.Name == this.newFloor
          )?.id
        }
      }
    }
  },

  mounted () {
    window.$(document).ready(function () {
      window.$('#modal__add_benhan').on('hidden.bs.modal', function (e) {
        // self.error = false
      })
    })
    this.handeDataRoom()
    this.fetchListFloors()
  },
  methods: {
    async fetchListFloors () {
      let self = this
      try {
        const params = {
          page_size: 1000,
          page_num: 1,
          ws_id: this.$globalClinic.id,
          order: 'desc',
          sort_by: 'created_at'
        }
        const response = await self.$rf
          .getRequest('DoctorRequest')
          .getListFloors(params)
        this.listFloors = response.data.data
      } catch (error) {
        console.log(error)
      }
    },
    async createFloor (value) {
      try {
        const params = {
          name: value,
          descriptiton: '',
          org_id: this.$globalClinic?.organization.id,
          ws_id: this.$globalClinic.id
        }
        await this.$rf
          .getRequest('DoctorRequest')
          .createFloor(params)
          .then(() => {
            this.$message.success('Tạo tầng mới thành công!')
          })
      } catch (error) {
        console.log(error)
      }
    },
    handeDataRoom () {
      if (this.dataRoom) {
        let item = this.dataRoom
        this.form.name = item.name
        this.form.address = item.address
        this.form.status = item.status
        this.form.price = item.price
        this.form.loc_id = item.loc_id
        if (item.status === ROOM_STATUS.CODE.ON_SERVICE) {
          this.isService = true
        }
      }
    },

    showModalAddRoom (show) {
      window.$('#modal__add_room').modal(show ? 'show' : 'hide')

      if (!show) {
        this.form.name = ''
        this.form.address = ''
        this.isService = false
        this.form.price = ''
      }
    },
    confirmRoom () {
      const self = this
      console.log(!self.form.name, self.form.name)
      console.log(!self.form.name.length, self.form.name.length)
      console.log(!self.form.address, self.form.address)
      console.log(!self.form.address.length, self.form.address.length)
      console.log(!self.form.price, self.form.price)
      console.log(!self.form.price.length, self.form.price.length)

      if (
        !self.form.name ||
        !self.form.name.length ||
        !self.form.address ||
        !self.form.address.length ||
        !self.form.price
      ) {
        self.$message({
          message: self.$t('Vui lòng điền đầy đủ thông tin'),
          type: 'error'
        })
        return
      }
      if (!this.update) {
        this.createRoom()
      } else {
        this.updateRoom()
      }
    },
    async createRoom () {
      let self = this
      const params = {
        name: this.form.name,
        address: this.form.address,
        price: parseFloat(
          Number(this.form?.price.toString()?.replaceAll(',', '')) || 0
        ),
        workspace_id: this.$globalClinic.id,
        loc_id: this.form.loc_id
      }

      this.loading = true
      let r = await self.$rf
        .getRequest('DoctorRequest')
        .createRoomService(params)
        .then((r) => {
          self.$emit('refresh')
          self.showModalAddRoom(false)
          return r
        })
        .catch((e) => {
          console.log(e)
          self.$toast.open({
            message: 'Có lỗi trong quá trình tạo',
            type: 'warning'
          })
          return false
        })
      this.loading = false

      return r
    },
    deleteRoom () {
      this.$confirm(
        `Bạn có chắc chắn muốn xoá ${this.dataRoom.name} không?`,
        this.$t('Cảnh báo')
      )
        .then((_) => {
          this.loading = true
          this.$rf
            .getRequest('DoctorRequest')
            .deleteRoom(this.dataRoom.ID)
            .then((r) => {
              this.$emit('refresh')
              this.showModalAddRoom(false)
            })
            .catch((e) => {
              console.log(e)
              this.$toast.open({
                message: 'Phòng đang được sử dụng!',
                type: 'warning'
              })
              return false
            })
          this.loading = false
        })
        .catch((_) => {})
    },
    async updateRoom () {
      console.log(this.dataRoom)
      let self = this
      try {
        this.loading = true
        if (this.isService === true) {
          this.form.status = ROOM_STATUS.CODE.ON_SERVICE
        } else {
          this.form.status = ROOM_STATUS.CODE.AVAILABLE
        }
        const params = {
          name: this.form.name,
          address: this.form.address,
          price: parseFloat(
            Number(this.form?.price.toString()?.replaceAll(',', '')) || 0
          ),
          status: this.form.status,
          loc_id: this.form.loc_id,
          workspace_id: this.$globalClinic.id
        }
        await self.$rf
          .getRequest('DoctorRequest')
          .updateRoomService(self.dataRoom.ID, params)
        self.$emit('refresh')
        self.showModalAddRoom(false)
        self.$toast.open({
          message: 'Cập nhật thành công',
          type: 'success'
        })
      } catch (error) {
        console.log(error)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
<style lang="scss" scoped>
// ::v-deep {
//   .el-input-group__append{
//   border: none;
//   }
// }
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
::v-deep.el-select {
  .el-input__inner {
    height: 32px;
  }
  .el-input__suffix {
    display: flex;
    align-items: center;
  }
}
</style>
