<template>
  <div class="flex items-center justify-between">
    <div class="flex items-center gap-3">
      <div class="flex flex-col">
        <!-- <label>Tìm kiếm</label> -->
        <el-input
          placeholder="Nhập tên bệnh nhân, mã điều trị, tên bệnh án, tên phòng"
          prefix-icon="el-icon-search"
          v-model="filters.keyword"
          clearable
          @input="handleChangeKeyword"
        ></el-input>
      </div>
      <div class="flex flex-col">
        <!-- <label>Thời gian</label> -->
        <el-date-picker
          v-model="filters.date"
          type="daterange"
          align="right"
          range-separator="đến"
          start-placeholder="Ngày vào"
          end-placeholder="Ngày ra"
          :picker-options="pickerOptions"
          @change="handleChangeDate"
          clearable
        ></el-date-picker>
      </div>
      <div class="flex flex-col">
        <!-- <label>Trạng thái</label> -->
        <el-select
          v-model="filters.status"
          placeholder="Chọn trạng thái"
          clearable
          @change="handleChangeStatus"
        >
          <el-option
            v-for="item in listFilterStatus"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </div>
    </div>
    <div>
      <el-button type="primary" @click="handleRegister">Đăng ký giường</el-button>
    </div>
  </div>
</template>
<script>
import { debounce } from 'lodash'
import { mixinQueryParams } from '@/utils/mixinQueryParams'
import { BED_STATUS } from '@/utils/constants'

const DEFAULT_FILTER = {
  keyword: '',
  room_id: '',
  ws_id: '',
  status: '',
  date: ''
}
export default {
  name: 'FilterRoom',
  mixins: [mixinQueryParams],
  data () {
    return {
      filters: { ...DEFAULT_FILTER },
      listFilterStatus: [
        { label: 'Tất cả trạng thái', value: '' },
        { label: 'Đã tiếp nhận', value: BED_STATUS.RECEIVED },
        { label: 'Đã ra viện', value: BED_STATUS.DISCHARGED },
        { label: 'Đã chuyển giường', value: BED_STATUS.SWITCH_BED },
        { label: 'Đã huỷ', value: BED_STATUS.CANCEL }
      ],
      listWs: [],
      BED_STATUS,
      pickerOptions: {
        shortcuts: [
          {
            text: 'Tuần tới',
            onClick (picker) {
              const end = new Date()
              const start = new Date()
              end.setTime(start.getTime() + 3600 * 1000 * 24 * 7)
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: 'Tháng tới',
            onClick (picker) {
              const end = new Date()
              const start = new Date()
              end.setTime(start.getTime() + 3600 * 1000 * 24 * 30)
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '3 tháng tới',
            onClick (picker) {
              const end = new Date()
              const start = new Date()
              end.setTime(start.getTime() + 3600 * 1000 * 24 * 90)
              picker.$emit('pick', [start, end])
            }
          }
        ]
      }
    }
  },
  mounted () {
    if (this.$route.query.keyword) {
      this.filters.keyword = this.$route.query.keyword
    }
    if (this.$route.query.status) {
      this.filters.status = +this.$route.query.status
    }
    if (this.$route.query.from && this.$route.query.to) {
      this.filters.date = [
        new Date(this.$route.query.from),
        new Date(this.$route.query.to)
      ]
    }
  },
  methods: {
    handleChangeKeyword: debounce(function () {
      this.handleSetFilter({ keyword: this.filters.keyword })
    }, 500),
    handleChangeDate () {
      if (this.filters.date === null) {
        this.handleSetFilter({
          from: null,
          to: null
        })
      }
      this.handleSetFilter({
        from: window.moment(this.filters.date[0]).format('YYYY-MM-DD'),
        to: window.moment(this.filters.date[1]).format('YYYY-MM-DD')
      })
    },
    handleChangeWS () {
      this.handleSetFilter({ ws_id: this.filters.ws_id })
    },
    handleChangeStatus () {
      this.handleSetFilter({ status: this.filters.status })
    },
    handleRegister () {
      this.$emit('register')
    }
  }
}
</script>
<style lang="scss">
.popper-clinic {
  width: 400px;
}
</style>